import * as React from 'react';
import { createFileRoute} from '@tanstack/react-router';
import PageLoader from '../../../microcomponents/PageLoader';
import { Space, Switch, Tooltip } from 'antd';
import { green, red } from '@ant-design/colors';
import { EyeFilled, EyeInvisibleFilled } from '@ant-design/icons';
import { supabase } from '../../../utils/supabase';
import ChangePassword from '../../../components/settings/ChangePassword';
import DangerZone from '../../../components/settings/DangerZone';
import useShopAttrs from '../../../utils/hooks/useShopAttrs';
import ShopInfoForm from '../../../components/settings/ShopInfoForm';

export const Route = createFileRoute('/_authenticated/settings')({
  component: RouteComponent,
  pendingComponent: PageLoader
});

function RouteComponent() {

  const shopAttrs = useShopAttrs();

  async function handlePublishing(value: boolean) {
    await shopAttrs.setQueryData({ ...shopAttrs.data, isVisible: value });
    await supabase.from('shops').update({ public: value }).eq('id', shopAttrs.data.id);
    await shopAttrs.invalidate();
  }

  return (
    <div className="container">
      <h1 style={{ margin: '40px 15px' }}>Impostazioni</h1>
      <Space direction={'vertical'} size={'large'} style={{ width: '100%' }}>
        <div style={{
          padding: 20,
          borderRadius: 10,
          backgroundColor: shopAttrs.data.isVisible ? green[0] : red[0],
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          borderColor: shopAttrs.data.isVisible ? green[2] : red[2],
          borderWidth: '1px',
          borderStyle: 'solid'
        }}>
          <Space size={'middle'}>
            {shopAttrs.data.isVisible ? <><EyeFilled style={{ fontSize: 25, color: green[6] }} />
              <div style={{ color: green[6] }}>
                <h3>Il negozio è pubblico</h3>
                <p>Il tuo negozio è visibile nell'app</p>
              </div>
            </> : <><EyeInvisibleFilled style={{ fontSize: 25, color: red[2] }} />
              <div style={{ color: red[5] }}>
                <h3>Il negozio è nascosto</h3>
                <p>Pubblica il negozio e fatti trovare nell'app</p>
              </div>
            </>}
          </Space>
          <Tooltip placement={'top'}
                   title={(!shopAttrs.data.completed || !shopAttrs.data.isSubscriptionValid) && 'Completa il profilo per poter pubblicare'}>
            <Switch onChange={handlePublishing} checked={shopAttrs.data.isVisible}
                    style={{ backgroundColor: shopAttrs.data.isVisible ? green[6] : 'grey' }}
                    unCheckedChildren={'Pubblica'}
                    checkedChildren={'Nascondi'}
                    disabled={!shopAttrs.data.completed || !shopAttrs.data.isSubscriptionValid} />
          </Tooltip>
        </div>
        <ShopInfoForm/>
        <ChangePassword/>
        <DangerZone/>
      </Space>
    </div>);
}
