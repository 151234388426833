import * as React from 'react'
import { createFileRoute, Outlet } from '@tanstack/react-router'
import PageLoader from '../../../../microcomponents/PageLoader'
import { useState } from 'react'
import { Link, useNavigate } from '@tanstack/react-router'
import { useDebouncedCallback } from 'use-debounce'
import { Button, Empty, Popover } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { notificationsQueryOptions } from '../../../../utils/queryOptions'
import { useQuery } from '@tanstack/react-query'
import NotificationCard from '../../../../components/NotificationCard'
import Wrapper from '../../../../components/Wrapper'
import Loader from '../../../../components/Loader'
import useShopAttrs from '../../../../utils/hooks/useShopAttrs';


export const Route = createFileRoute('/_authenticated/notifications/')({
  component: RouteComponent,
  pendingComponent: PageLoader,
})

function RouteComponent() {
  const notifications = useQuery(notificationsQueryOptions())
  const navigate = useNavigate()
  const shopAttrs = useShopAttrs()

  /*async function onSearch(value) {
    setLoading(true)

    try {
      let {data: notifications, error} = await supabase
        .from('notifications')
        .select('*').eq('shop_id', userId.value).like('title', '%' + value + '%')

      if (error) {
        throw error
      }


      setNotifications(notifications)
      setLoading(false)

    } catch (e) {

    } finally {
      setLoading(false)
    }
  }

  const debouncedSearch = useDebouncedCallback(
    // function
    (value) => {
      onSearch(value)
    },
    400
  );*/

  return (
    <>
      <Outlet />
      <div style={{ marginBottom: 40 }}></div>
      <div className={'customizePageW'}>
        <div
          style={{
            maxWidth: '400px',
            display: 'flex',
            flexDirection: 'column',
            gap: 30,
            width: '100%',
          }}
        >
          <Wrapper>
            <h2 style={{ marginBottom: 10 }}>Notifiche</h2>
            <Popover
              title={
                !shopAttrs.data.isVisible &&
                'Il tuo negozio deve essere pubblico per poter inviare notifiche'
              }
            >
              <Button
                disabled={!shopAttrs.data.isVisible}
                type={'primary'}
                size={'large'}
                style={{ width: '100%', marginTop: 20 }}
                onClick={() => {
                  navigate({ to: '/notifications/new' })
                }}
              >
                <PlusOutlined />
                Crea una nuova notifica
              </Button>
            </Popover>
          </Wrapper>
          {/*       <Input onChange={(e) => {
            debouncedSearch(e.target.value)
          }} placeholder='Cerca per titolo...'/>*/}

          {!notifications.isLoading ? (
            <>
              {notifications?.data?.map((item, index) => (
                <NotificationCard key={item.id} item={item} />
              ))}
              {!notifications?.data?.[0] && (
                <Empty
                  description={
                    <p>
                      Ancora nessuna notifica <br />
                      <Link to={'/notifications/new'}>Creane una!</Link>
                    </p>
                  }
                />
              )}
            </>
          ) : (
            <Loader />
          )}
        </div>
      </div>
    </>
  )
}
