import * as React from 'react';
import { createFileRoute, Link } from '@tanstack/react-router';
import { Alert, Button, Checkbox, Flex, Form, Input, message } from 'antd';
import { useState } from 'react';
import { supabase } from '../../../utils/supabase';
import { trackEvent, trackFormConversion } from '../../../utils/Matomo/tracking_functions';
import { useNavigate } from '@tanstack/react-router';
import { apiV1 } from '../../../utils/apiClient';
import { EmailCheckResponse } from '@next-is-free-monorepo/next-is-free-types';
import posthog from 'posthog-js';

export const Route = createFileRoute('/_public/sign-up')({
  component: RouteComponent
});

function RouteComponent() {
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState<undefined | { description: any }>(undefined);
  const [resetPasswordSent, setResetPasswordSent] = useState(false);
  const [form] = Form.useForm();

  const navigate = useNavigate();

  async function resetPassword() {
    const email = form.getFieldValue('email');
    const { data, error } = await supabase.auth.resetPasswordForEmail(email, {
      redirectTo: 'https://app.nextisfree.io/update-password/'
    });
    if (error) {
      message.error(error.message);
      return;
    }
    setResetPasswordSent(true);
    trackEvent('Password Recovery', 'Reset email sent', email);
  }

  async function onFinish(values: { email: string, password: string }) {
    setLoading(true);
    const emailCheck = await apiV1.get<EmailCheckResponse>(`/public/is-shop/${values.email}`, { isPublic: true });
    if (emailCheck === 'isShop') {
      setLoading(false);
      return setAlert({
        description: <p>È già presente un account associato a questo indirizzo email → <a href={'/login'}>Accedi</a></p>
      });
    } else if (emailCheck === 'isNotShop') {
      setLoading(false);
      return setAlert({
        description: <p>È già presente un account associato a questo indirizzo email, hai dimenticato la password? → <a
          onClick={resetPassword}>Recupera password</a></p>
      });
    }

    const { data, error } = await supabase.auth.signUp({
      email: values.email,
      password: values.password,
      options: {
        emailRedirectTo: 'https://app.nextisfree.io/welcome/',
        data: {
          shop: true
        }
      }
    });

    if (error) {
      return messageApi.error(error.message);
    }

    if (data) {
      posthog.capture('sign-up-form-submitted', { email: values.email });
      /* trackFormConversion('signUp');*/
      await navigate({ to: '/confirm-email', search: { email: values.email } });
    }
  }

  return (
    <div
      data-matomo-form={true}
      data-matomo-name="signUp"
      style={{ width: '100%' }}
    >
      <h1 style={{ marginBottom: 30 }}>Crea un nuovo account</h1>
      <Form
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
        form={form}
      >
        {contextHolder}
        <Form.Item
          label="Email"
          name="email"
          required={false}
          validateDebounce={1000}
          rules={[
            {
              required: true,
              type: 'email',
              message: 'Email non valida'
            }
          ]}
        >
          <Input placeholder={'Email'} />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          required={false}
          validateDebounce={1000}
          rules={[
            {
              required: true,
              message: 'Password non valida'
            },
            { min: 8, message: 'La password deve essere minimo 8 caratteri' }
          ]}
        >
          <Input.Password placeholder={'Password'} />
        </Form.Item>
        <Form.Item
          style={{ marginBottom: 0 }}
          name={'terms-conditions'}
          required
          valuePropName="checked"
          rules={[
            {
              validator: (_, value) =>
                value
                  ? Promise.resolve()
                  : Promise.reject(
                    new Error(
                      'Per continuare, è necessario spuntare questa casella.'
                    )
                  )
            }
          ]}
        >
          <Flex gap={8}>
            <Checkbox />
            <p style={{ color: 'gray', fontSize: 12 }}>
              Dichiaro di aver letto, compreso e accettato i <a
              target="_blank"
              href={'https://nextisfree.io/termini-condizioni'}
              style={{
                color: 'black',
                fontWeight: 500
              }}
            >
              termini di utilizzo
            </a> e le <a target="_blank"
                        href={'https://nextisfree.io/termini-condizioni'}
                        style={{
                          color: 'black',
                          fontWeight: 500
                        }}>Condizioni di Servizio</a>
            </p>
          </Flex>
        </Form.Item>
        <Form.Item
          style={{ marginTop: 10 }}
          name={'privacy-policy'}
          valuePropName="checked"
        >
          <Flex gap={8}>
            <Checkbox />
            <p style={{ color: 'gray', fontSize: 12 }}>
              Dichiaro di aver letto,  <a
              target="_blank"
              href={'https://nextisfree.io/informativa-privacy-business'}
              style={{
                color: 'black',
                fontWeight: 500
              }}
            >
              l'informativa sulla privacy
            </a> e presto consenso a tutti i dati trattati.
            </p>
          </Flex>
        </Form.Item>
        {alert && !resetPasswordSent &&
          <Alert type={'error'} description={alert?.description} />
        }
        {resetPasswordSent &&
          <Alert type={'success'}
                 description={'Se l\'email inserita appartiene ad un account, riceverai un\'email per il recupero della password'} />
        }
        <div style={{ textAlign: 'center' }}>
          <Form.Item>
            <Button
              loading={loading}
              disabled={loading}
              style={{ width: '100%', marginTop: 10 }}
              type="primary"
              htmlType="submit"
              size="large"
            >
              Crea un account
            </Button>
            {/*              <p style={{ color: "#f5b141", marginTop: 5 }}>
                Non è richiesta la carta di credito.
              </p>*/}
          </Form.Item>
        </div>
      </Form>
      <p
        style={{
          fontSize: 14,
          textAlign: 'center',
          width: '100%',
          margin: '10px 0',
          marginTop: 40
        }}
      >
        Hai già un account?
      </p>
      <Link to={'/login'}>
        <Button size={'large'} style={{ width: '100%' }}>
          Accedi
        </Button>
      </Link>
    </div>
  );
}
