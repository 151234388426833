import {Button, Flex, Input, message, Space} from "antd";
import {useState} from "react";
import {CloseOutlined} from "@ant-design/icons";
import {supabase} from "../../utils/supabase";
import Wrapper from "../Wrapper";


const ChangePassword = () => {
    const [disabled, setDisabled] = useState(true);
    const [newPassword, setNewPassword] = useState("passworddidefault");
    const [loading, setLoading] = useState(false);

    async function updatePassword() {
        setLoading(true);
        const {data, error} = await supabase.auth.updateUser({
            password: newPassword,
        });
        if (error) {
            message.error(error.message);
            setLoading(false);

            return;
        }
        if (data) {
            message.success("Password updated successfully");
            setDisabled(true);
            setNewPassword("passworddidefault");
            setLoading(false)
        }
    }

    return (
        <Wrapper disabled={disabled} p={20}>
            <h3 style={{marginBottom: 10}}>Cambia password</h3>
            <Flex gap={6}>
                {disabled ?
                    <Input.Password
                        style={{height: 33}}
                        value={newPassword}
                        disabled={true}
                        visibilityToggle={{visible: false}}
                        iconRender={()=>(<div/>)}
                        placeholder={"Nuova password"}
                    /> :
                    <Input.Password
                        style={{height: 33}}
                        value={newPassword}
                        placeholder={""}
                        onChange={(e) => {
                            setNewPassword(e.target.value);
                        }}
                    />
                }


                {disabled ? (
                    <Button
                        type={'primary'}
                        style={{width: 95}}
                        onClick={() => {
                            setDisabled(false);
                            setNewPassword("");
                        }}
                    >
                        Cambia
                    </Button>
                ) : (
                    <Space>
                        <Button loading={loading} type={"primary"} onClick={updatePassword}>
                            Cambia
                        </Button>
                        <Button
                            onClick={() => {
                                setDisabled(true);
                                setNewPassword("passworddidefault");
                            }}
                        >
                            <CloseOutlined/>
                        </Button>
                    </Space>
                )}
            </Flex>
        </Wrapper>
    );
};
export default ChangePassword;
