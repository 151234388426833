import { Button, Form, Input, message, Select, Skeleton } from 'antd';
import { useState } from 'react';
import { supabase } from '../../utils/supabase';
import Wrapper from '../Wrapper';
import useShopAttrs from '../../utils/hooks/useShopAttrs';
import { useSuspenseQuery } from '@tanstack/react-query';
import { categoriesQueryOptions } from '../../utils/queryOptions';

const ShopInfoForm = () => {

  const [disabled, setDisabled] = useState(true);

  const shopAttrs = useShopAttrs();
  const [form] = Form.useForm();

  const categories = useSuspenseQuery(categoriesQueryOptions());

  /*    useEffect(() => {
          async function getData() {

              const {
                  data: shop,
                  error: shopDataError
              } = await supabase.from('shops').select('business_name, vat, business_category(id, italian)').eq('id', shopAttrs.data.id)
              const values= {...shop[0], business_category: shop[0].business_category.id}
              form.setFieldsValue(values)
          }

          getData().then(() => setLoading(false))
      }, [])*/

  type FormValues = { business_name: string, vat: string, business_category: string }

  async function onFinish(values: FormValues) {
    setDisabled(true);
    const { data, error } = await supabase.from('shops').update(values).eq('id', shopAttrs.data.id);
    if (error) {
      message.error('Qualcosa è andato storto');
      return;
    }

    message.success('Informazioni aggiornate');
    await shopAttrs.invalidate()

  }


  return (
    <Wrapper p={20} disabled={disabled}>
      <Form initialValues={{
        business_name: shopAttrs.data.business_name,
        vat: shopAttrs.data.vat,
        business_category: shopAttrs.data.business_category
      }} onFinish={onFinish} form={form} style={{ textAlign: 'right' }}
            layout={'vertical'} disabled={disabled}>
        <h3 style={{ marginBottom: 20, textAlign: 'left' }}>Riepilogo</h3>
        <Form.Item name={'business_name'} label={'Nome del negozio'}>
          <Input size={'large'} />
        </Form.Item>
        <Form.Item name={'vat'} label={'Partita IVA'}>
          <Input size={'large'} />
        </Form.Item>
        <Form.Item name={'business_category'} label={'Categoria'}>
          <Select style={{ textAlign: 'left' }} size={'large'}>
            {categories.data.map((item) => (
              <Select.Option key={item.id} value={item.id}>{item.italian}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        {disabled ? <Button type={'primary'} onClick={() => setDisabled(false)} disabled={false}>Modifica</Button> :
          <Button style={{ borderRadius: 30 }} onClick={() => form.submit()} type={'primary'}>Salva</Button>}
      </Form>
    </Wrapper>

  );
};
export default ShopInfoForm;
