import { createFileRoute } from '@tanstack/react-router';
import { DatePicker, Flex, Segmented, Select, Skeleton } from 'antd';

import dayjs from 'dayjs';
import TotalCustomersNumber from '../../../components/Analytics/TotalCustomersNumber';
import CustomersActiveNotifications from '../../../components/Analytics/CustomersActiveNotifications';
import NotificationsFrequency from '../../../components/Analytics/NotificationsFrequency';
import CustomersNumberTrend from '../../../components/Analytics/CustomersNumberTrend';
import AllocatedPointsTrend from '../../../components/Analytics/AllocatedPointsTrend';
import SpentPointsTrend from '../../../components/Analytics/SpentPointsTrend';
import GoalsRank from '../../../components/Analytics/GoalsRank';
import MainActivityHours from '../../../components/Analytics/MainActivityHours';
import NotificationsReadTime from '../../../components/Analytics/NotificationsReadTime';
import TotalNotificationsSent from '../../../components/Analytics/TotalNotificationsSend';
import { computed, signal } from '@preact/signals-react';
import PageLoader from '../../../microcomponents/PageLoader';
import { Suspense, useState } from 'react';
import Wrapper from '../../../components/Wrapper';
import SalesPointsSelect from '../../../components/SalesPointsSelect';
import React from 'react';


export const timeSpan = signal<'year' | 'month' | 'week'>('month');
export const endDate = signal(dayjs());
export const startingDate = computed(() => {
  switch (timeSpan.value) {
    case 'year':
      return endDate.value.subtract(1, 'year');

    case 'month':
      return endDate.value.subtract(1, 'month');

    case 'week':
      return endDate.value.subtract(1, 'week');
  }
});


export const Route = createFileRoute('/_authenticated/analytics')({
  component: Analytics,
  pendingComponent: PageLoader
});

function Analytics() {

  const [selectedSalesPointId, setSelectedSalesPointId] = useState('');

  const disabledDate = (current: any) => {
    // Can not select days before today and today
    return current && current > dayjs().endOf('day');
  };

  return (
    <div>
      <Flex
        wrap={'wrap'}
        gap={10}
        style={{ marginBottom: 30 }}
        justify={'space-between'}
      >
        <h1>Analitiche</h1>
        <Flex wrap={'wrap'} gap={8}>
          <Suspense fallback={<Skeleton.Input style={{ width: 200 }} active />}>
            <SalesPointsSelect selectedSalesPointId={selectedSalesPointId}
                               setSelectedSalesPointId={setSelectedSalesPointId} />
          </Suspense>
          <DatePicker
            allowClear={false}
            format="YYYY-MM-DD"
            disabledDate={disabledDate}
            style={{ backgroundColor: 'white' }}
            onChange={(date) => (endDate.value = date)}
            defaultValue={endDate.value}
          />
          <div style={{ flex: 1 }}>
            <Segmented
              options={[
                { value: 'year', label: 'Un anno' },
                {
                  value: 'month',
                  label: 'Un mese'
                },
                { value: 'week', label: 'Una settimana' }
              ]}
              value={timeSpan.value}
              onChange={(e: any) => {
                timeSpan.value = e;
              }}
            />
          </div>
        </Flex>
      </Flex>
      <div className={'smallAnalyticsGrid'}>
        <Suspense fallback={<Wrapper mh={105} loading />}>
          <TotalCustomersNumber />
        </Suspense>
        <Suspense fallback={<Wrapper loading />}>
          <CustomersActiveNotifications />
        </Suspense>
        <Suspense fallback={<Wrapper loading />}>
          <TotalNotificationsSent />
        </Suspense>
        <Suspense fallback={<Wrapper loading />}>
          <NotificationsFrequency />
        </Suspense>
      </div>
      <div className={'analyticsGrid'}>
        <Suspense fallback={<Wrapper loading mh={460} />}>
          <CustomersNumberTrend />
        </Suspense>
        <Suspense fallback={<Wrapper loading mh={460} />}>
          <AllocatedPointsTrend selectedSalesPointId={selectedSalesPointId} />
        </Suspense>
        <Suspense fallback={<Wrapper loading mh={460} />}>
          <SpentPointsTrend selectedSalesPointId={selectedSalesPointId} />
        </Suspense>
        <Suspense fallback={<Wrapper loading mh={460} />}>
          <GoalsRank selectedSalesPointId={selectedSalesPointId} />
        </Suspense>
        <Suspense fallback={<Wrapper loading mh={460} />}>
          <MainActivityHours selectedSalesPointId={selectedSalesPointId} />
        </Suspense>
        <Suspense fallback={<Wrapper loading mh={460} />}>
          <NotificationsReadTime />
        </Suspense>
      </div>
    </div>
  );
}
