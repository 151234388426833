import ContainerBlock from './ContainerBlock';

import { groupByHours, sumYValues } from '../../utils/analytics';
import SingleLineChart from './charts/SingleLineChart';
import { useSuspenseQuery } from '@tanstack/react-query';
import { transactionsQueryOptions } from '../../utils/queryOptions';
import { endDate, startingDate } from '../../src/routes/_authenticated/analytics';


const MainActivityHours = ({ selectedSalesPointId }:{selectedSalesPointId: string} ) => {

  const transactions = useSuspenseQuery(transactionsQueryOptions(endDate.value, startingDate.value, selectedSalesPointId));

  const hourGroupedTransactions = groupByHours(transactions.data);

  const data = Object.entries(hourGroupedTransactions).map((item: any) => {
    return {
      x: item[0],
      y: item[1].length
    };
  });

  return (
    <ContainerBlock total={sumYValues(data)} title={'Totale transazioni per fascia oraria'}>
      <SingleLineChart data={data} />
    </ContainerBlock>);
};
export default MainActivityHours;
