import ContainerBlock from './ContainerBlock';
import { groupByHours, sumYValues } from '../../utils/analytics';
import SingleLineChart from './charts/SingleLineChart';
import { useSuspenseQuery } from '@tanstack/react-query';
import { notificationsQueryOptions, readNotificationsQueryOptions } from '../../utils/queryOptions';
import { endDate, startingDate } from '../../src/routes/_authenticated/analytics';
import React from 'react';

const NotificationsReadTime = () => {

  const readNotifications = useSuspenseQuery(readNotificationsQueryOptions(startingDate.value, endDate.value));

  const groupedReadNotifications = groupByHours(readNotifications.data);

  const data = Object.entries(groupedReadNotifications).map((item: any) => {
    return {
      x: item[0],
      y: item[1].length
    };
  });

  return (
    <ContainerBlock total={sumYValues(data)} title={'Totale lettura notifiche per fascia oraria'}>
      <SingleLineChart data={data} />
    </ContainerBlock>);
};
export default NotificationsReadTime;
