import * as React from 'react'
import { createFileRoute, useNavigate } from '@tanstack/react-router';
import { Button, Flex, Input, message } from 'antd'
import { useState } from 'react'
import { supabase } from '../../../utils/supabase'
import AuthLayout from '../../../layouts/AuthLayout';
import OnBoardLayout from '../../../layouts/OnBoardLayout';
import Wrapper from '../../../components/Wrapper';
import { COLORS } from '@next-is-free-monorepo/globals';
import { signOut } from '../../../utils/signOut';

export const Route = createFileRoute('/_on-boarding/update-password')({
  component: RouteComponent,
})

function RouteComponent() {
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  async function updatePassword() {
    setLoading(true)
    const { data, error } = await supabase.auth.updateUser({
      password: password,
      data: {
        shop: true,
      }
    })
    if (error) {
      message.error(error.message)
      setLoading(false)
      return
    }
    if (data) {
      message.success('Password cambiata con successo')
      setTimeout(() => {
        navigate({ to: '/analytics' })
      }, 1000)
    }
  }

  return (
    <div>
      <Wrapper style={{
        position: 'absolute',
        right: '50%',
        top: '50%',
        backgroundColor: 'white',
        transform: 'translate(50%, -50%)',
        padding: 20,
        borderRadius: 20,
        width: 'calc(100% - 80px)',
        maxWidth: 350
      }}>
        <Flex
          vertical
          gap={10}
        >
          <div>
            <h3>Cambia password</h3>
            <p style={{ color: COLORS.gray, fontSize: 14, marginBottom: 20 }}>Scegli una nuova password</p>
          </div>
          <Input.Password
            style={{ width: '100%' }}
            size={'large'}
            onChange={(e) => {
              setPassword(e.target.value)
            }}
            placeholder={'Nuova password'}
          ></Input.Password>

          <Button
            loading={loading}
            type={'primary'}
            size={'large'}
            onClick={updatePassword}
            style={{marginTop: 20}}
          >
            Cambia
          </Button>
          <Button onClick={()=>signOut()} size={'large'}>
            Esci
          </Button>
        </Flex>
      </Wrapper>
    </div>

  )
}
