import Wrapper from '../Wrapper';
import { green, red } from '@ant-design/colors';
import { Button, Flex } from 'antd';
import * as React from 'react';
import { SubscriptionAttrs } from '@next-is-free-monorepo/next-is-free-types';
import { apiV1 } from '../../utils/apiClient';

const PaymentMethodOverview = ({ subscription }: { subscription: SubscriptionAttrs }) => {

  async function updatePaymentMethod(){
    const url = await apiV1.get<string>(`/shop/stripe-billing-portal?flow_data_type=payment_method_update`)
    window.location.replace(url)
  }

  return (<>
    {!subscription.default_payment_method ? (
      <Wrapper
        mt={10}
        style={{
          backgroundColor: red[0],
          borderRadius: 10,
          marginTop: 10,
          borderColor: red[1],
          border: '1px solid',
          color: red[5]
        }}
      >
        <h3 style={{ marginBottom: 5 }}>
          Nessun metodo di pagamento inserito
        </h3>
        <p>
          Se non inserisci un metodo di pagamento l'abbonamento sarà
          disattivato al termine della prova gratuita e il tuo negozio non
          sarà più disponibile.
        </p>
        <div style={{ marginTop: 20, textAlign: 'right' }}>
          <Button onClick={updatePaymentMethod} type={'primary'} danger>
            Aggiungi metodo di pagamento
          </Button>
        </div>
      </Wrapper>
    ) : (
      <Wrapper
        mt={10}
      >
        <h3>Metodo di pagamento</h3>
        <p
          style={{ marginTop: 10 }}>{subscription.default_payment_method.type === 'card' ? 'Carta' : 'Addebito diretto'}</p>
        <Flex justify={'space-between'} align={'center'}>
          <div>
            <Flex
              style={{
                padding: 6,
                backgroundColor: green[1],
                borderRadius: 4,
                marginTop: 0
              }}
              gap={10}
            >
              {/* <PaymentIcon
                                                type={subscription.default_payment_method.cardBrand}
                                                    format="flatRounded"
                                                width={30}
                                                 />*/}
              <p style={{ fontWeight: 100, color: green[6] }}>
                ✱✱✱✱ ✱✱✱✱ ✱✱✱✱{' '}
                <span style={{ fontWeight: 600 }}>
                                            {subscription.default_payment_method.last4}
                                                 </span>
              </p>
            </Flex>
          </div>
          <Button type={'primary'} onClick={updatePaymentMethod}>Aggiorna</Button>
        </Flex>
      </Wrapper>
    )}
  </>);
};
export default PaymentMethodOverview;
