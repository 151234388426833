import { createFileRoute, Outlet, redirect } from '@tanstack/react-router';
import LoginLayout from '../../layouts/LoginLayout';
import { supabase } from '../../utils/supabase';

export const Route = createFileRoute('/_public')({
  component: RouteComponent,
  beforeLoad: async ({ location, context }) => {
    const {data: {session}} = await supabase.auth.getSession();
    console.log('session', session);
    if (session) {
      throw redirect({
        to: '/analytics'
      });
    }
  }
});

function RouteComponent() {
  return <LoginLayout><Outlet /></LoginLayout>;
}
