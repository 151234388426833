import { Button, Flex, Input, Modal } from 'antd';
import { ReactNode, useState } from 'react';
import { CheckCircleOutlined, WarningOutlined } from '@ant-design/icons';
import { green } from '@ant-design/colors';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';

interface ConfirmModalProps {
  open: boolean,
  setOpen: (open: boolean) => void,
  danger: boolean;
  title: string;
  buttonText: string;
  children: ReactNode;
  mutationOptions: UseMutationOptions
}

const ConfirmModal = ({
                        open,
                        setOpen,
                        danger,
                        title,
                        buttonText,
                        children,
                        mutationOptions
                      }: ConfirmModalProps) => {

  const [inputValue, setInputValue] = useState('');

  const mutation = useMutation(mutationOptions);

  function checkSafeDelete() {
    if (inputValue.toLowerCase() === 'elimina') {
      mutation.mutate();
    }
  }

  return (
    <Modal onCancel={() => setOpen(false)} footer={false} open={open} destroyOnClose>
      <Flex vertical align={'center'} style={{ textAlign: 'center', textWrap: 'balance', whiteSpace: 'pretty' }}>
        <h1>{danger && <WarningOutlined style={{ color: 'red' }} />} {title}</h1>
        <div style={{ padding: 20, width: '100%' }}>
          {children}
        </div>
        {danger ? (
          <Flex vertical gap={10}>
            <p style={{ color: 'red' }}>Inserisci "elimina" per confermare</p>
            <Input size={'middle'} style={{ height: 30, textAlign: 'center' }} placeholder={'Elimina'}
                   onChange={(e) => setInputValue(e.target.value)} />
            <Button loading={mutation.isPending} disabled={inputValue.toLowerCase() !== 'elimina'}
                    onClick={checkSafeDelete}
                    type={'primary'} danger>{buttonText}</Button>
          </Flex>
        ) : (
          <Button style={{ backgroundColor: green[5] }} type={'primary'} onClick={() => mutation.mutate()}
                  loading={mutation.isPending}><CheckCircleOutlined />{buttonText}</Button>
        )}
      </Flex>
    </Modal>
  );
};
export default ConfirmModal;
