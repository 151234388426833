export const COLORS = {
  white: 'white',
  veryLightGray: '#f7f7f7',
  lightGray: '#efefef',
  mediumGray: '#d8d8d8',
  gray: '#979797',
  darkBg: '#373B44',
  black: '#101921',
  red: '#F22626',
  primary: '#FFc600',
  primaryLight: '#FFE899',
  businessColor: '#0F1224',
  businessAppBg: '#00061A',
  businessAppBgLight: '#0D1326',
  businessPrimary: '#90A6F0',
  'gray-1': '#BDC4DB',
  'gray-2': '#D7DCE9',
  'gray-3': '#EEF0F6',
  'gray-text': '#6D7078'
}
