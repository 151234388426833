import { z } from 'zod';

export const invoiceStatusSchema = z.enum(['draft', 'open', 'paid', 'uncollectible', 'void',])

export type InvoiceStatus = z.infer<typeof invoiceStatusSchema>

export const invoiceSchema = z.object({
  id: z.string(),
  amount: z.number(),
  currency: z.string(),
  hosted_invoice_url: z.string().nullish(),
  description: z.string().nullable(),
  date: z.string(),
  total: z.number(),
  status: invoiceStatusSchema.nullable(),
  paid: z.boolean(),
})

export type Invoice = z.infer<typeof invoiceSchema>
