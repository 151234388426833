import React, {useEffect, useState} from "react";
import {authGet} from "../utils/authCalls";
import {Modal} from "antd";
import TopInfoBanner from "./TopInfoBanner";
import PaymentForm from "./onBoarding/PaymentForm";
import { useSuspenseQuery } from '@tanstack/react-query';
import { subscriptionQueryOptions } from '../utils/queryOptions';
import { useNavigate } from '@tanstack/react-router';
import { apiV1 } from '../utils/apiClient';

const SubscriptionCheckBanner = () => {
    const [loading, setLoading] = useState(false);
    const subscription = useSuspenseQuery(subscriptionQueryOptions())
    const navigate = useNavigate()

  async function updatePaymentMethod(){
    const url = await apiV1.get<string>(`/shop/stripe-billing-portal?flow_data_type=payment_method_update`)
    window.location.replace(url)
  }

    if (subscription.data.status === 'incomplete' || subscription.data.status === 'paused') {
        return (
            <Modal footer={''} styles={{
                mask: {
                    backdropFilter: 'blur(5px)',
                }
            }} open closable={false}>
                <div>
                    <h2 style={{textAlign: 'center'}}>Completa il pagamento per continuare</h2>
                    <PaymentForm/>
                </div>
            </Modal>
        )
    }

    if (!subscription.data.status) {
        return (
            <TopInfoBanner
                boldText={"Non hai un abbonamento attivo "}
                text={""}
                buttonText={"Abbonati ora"}
                onClick={() => navigate({to: "/billings"})}
                buttonLoading={loading}
            />
        );
    }

    if (!subscription.data.default_payment_method)
        return (
            <TopInfoBanner
                boldText={"Nessun metodo di pagamento "}
                text={", inserisci un metodo di pagamento valido"}
                buttonText={"Aggiungi metodo di pagamento"}
                onClick={() => {
                    setLoading(true);
                    updatePaymentMethod();
                }}
                buttonLoading={loading}
            />
        );
    else return null
};
export default SubscriptionCheckBanner;
