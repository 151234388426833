import { z } from 'zod';
import { CallToActionSchema } from './callToAction';

export const NotificationSchema = z.object({
  id: z.string().uuid(),
  title: z.string(),
  text: z.string().optional(),
  image: z.boolean(),
  created_at: z.string().date()
});

export type Notification = z.infer<typeof NotificationSchema>

export const NewNotificationSchema = z.object({
  id: z.string().uuid(),
  title: z.string(),
  text: z.string(),
  image: z.boolean(),
  business_name: z.string(),
  shop_latest_update_at: z.string(),
  call_to_actions: z.array(CallToActionSchema).nullish(),
});

export type NewNotification = z.infer<typeof NewNotificationSchema>


export const PushNotificationTypeSchema = z.enum(['shop_news', 'trigger_data_update']);

export type PushNotificationType = z.infer<typeof PushNotificationTypeSchema>

export const pushNotificationsTypePrefixes: { [key in PushNotificationType]: string } = {
  shop_news: 'v2%',
  trigger_data_update: 'trigger_data_update%'
};

export const UserShopNewsSchema = z.object({
  id: z.string().uuid(),
  shop_id: z.string().uuid(),
  title: z.string(),
  text: z.string().optional(),
  image: z.boolean(),
  business_name: z.string(),
  read: z.boolean(),
  shop_latest_update_at: z.string().date(),
  created_at: z.string().date(),
  type: PushNotificationTypeSchema.extract(['shop_news'])
});

export type UserShopNews = z.infer<typeof UserShopNewsSchema>

export const PushNotificationDataSchema = UserShopNewsSchema.or(z.object({
  type: PushNotificationTypeSchema.extract(['trigger_data_update']),
  data_key: z.enum(['fetchPrivacySettings'])
}));

export type PushNotificationData = z.infer<typeof PushNotificationDataSchema>

export const UserNotificationsResultsSchema = z.object({
  notifications: z.array(UserShopNewsSchema),
  hasMore: z.boolean(),
  nextPage: z.number().nullable()
});

export type UserNotificationsResults = z.infer<typeof UserNotificationsResultsSchema>


export const NotificationExtraContent = z.object({
  call_to_actions: CallToActionSchema.array().nullish()
});

export type NotificationExtraContent = z.infer<typeof NotificationExtraContent>
