import { createFileRoute, redirect } from '@tanstack/react-router'
import { z } from 'zod'
import { zodSearchValidator } from '@tanstack/router-zod-adapter'
import { Button, Card, Flex, Input, message } from 'antd'

import { useState } from 'react'
import { supabase } from '../../../utils/supabase'
import { trackEvent } from '../../../utils/Matomo/tracking_functions'
import { COLORS } from '@next-is-free-monorepo/globals'

const productSearchSchema = z.object({
  email: z.string().email(),
})

export const Route = createFileRoute('/_public/confirm-email')({
  component: RouteComponent,
  validateSearch: zodSearchValidator(productSearchSchema),
  onError: (err) => {
    throw redirect({ to: '/sign-up' })
  },
})

function RouteComponent() {
  const [opt, setOpt] = useState<string>('')

  const { email } = Route.useSearch()

  async function verifyCode() {
    const { data, error } = await supabase.auth.verifyOtp({
      email,
      token: opt,
      type: 'email',
    })
    if (error) {
      message.error('Codice errato')
      return
    }
    if (data) {
      trackEvent('User Registration', 'OTP Verified', email)
      window.location.reload()
    }
  }

  async function resend() {
    await supabase.auth.resend({
      type: 'signup',
      email: email,
      options: {
        emailRedirectTo: 'https://app.nextisfree.io/welcome/',
      },
    })
  }

  return (
      <Card
        style={{
          backgroundColor: 'white',
          width: '90%',
          maxWidth: 580,
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <h1 style={{ textAlign: 'center' }}>Verifica indirizzo email</h1>
        <p
          style={{
            marginTop: 0,
            fontSize: 14,
            textAlign: 'center',
            marginBottom: 30,
            color: COLORS.gray,
            lineHeight: 1.5,
          }}
        >
          Ti abbiamo inviato una email a: {email} per verificare il tuo
          indirizzo email. Clicca su "Verifica Email" per continuare o inserisci
          il codice di verifica.
        </p>
        <Flex
          data-matomo-form={true}
          data-matomo-name="emailVerificationOTP"
          gap={10}
          style={{ marginTop: 10, flexDirection: 'column' }}
          align={'center'}
        >
          <Input.OTP
            style={{ width: 260 }}
            size={'large'}
            length={6}
            onChange={(value) => {
              setOpt(value)
            }}
          />
          <Button
            onClick={verifyCode}
            style={{ width: 260 }}
            size={'large'}
            type={'primary'}
          >
            Verifica
          </Button>
        </Flex>
        <h3 style={{ marginTop: 30, textAlign: 'center' }}>
          Non vedi l'email?
        </h3>
        <p style={{ textAlign: 'center' }}>
          Controlla la cartella spam o{' '}
          <a
            onClick={resend}
            style={{ color: 'black', textDecoration: 'underline' }}
          >
            invia di nuovo
          </a>
        </p>
      </Card>
  )
}
