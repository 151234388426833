import { blue, gray, green, grey } from '@ant-design/colors';
import { Button, Flex, Tooltip } from 'antd';
import { ClockCircleOutlined, PauseOutlined } from '@ant-design/icons';
import { useState } from 'react';
import ConfirmModal from '../ConfirmModal';
import dayjs from 'dayjs';
import Wrapper from '../Wrapper';
import ChangePlanModal from './ChangePlanModal';
import { SubscriptionAttrs } from '@next-is-free-monorepo/next-is-free-types';
import { Stripe } from 'stripe';
import { UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { apiV1 } from '../../utils/apiClient';
import React from 'react';

const SubscriptionOverview = (subscription: SubscriptionAttrs) => {

  const [autoRenewModalOpen, setAutoRenewModalOpen] = useState(false);
  const [cancelRenewModalOpen, setCancelRenewModalOpen] = useState(false);
  const [changePlanModalOpen, setChangePlanModalOpen] = useState(false);

  const queryClient = useQueryClient();
  const toggleAutoRenewMutation: UseMutationOptions = {
    mutationKey: ['subscription', 'renew'],
    mutationFn: async () => apiV1.put(`/shop/subscription/${subscription.id}/auto-renew/${subscription.cancel_at_period_end}`, {}),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['subscription'], exact: false });
      setAutoRenewModalOpen(false);
      setCancelRenewModalOpen(false);
    }
  };

  return (
    <div>
      {subscription.status === 'trialing' &&
        <div
          style={{ backgroundColor: blue[0], padding: 20, borderRadius: 20, color: blue[4], marginBottom: 15 }}>
          <Flex align={'center'}>
            <div>
              <ClockCircleOutlined style={{ fontSize: 20, marginRight: 10 }} />
            </div>
            <div>
              <p style={{ fontWeight: 600 }}>La prova gratuita scadrà tra {subscription.trial_days_left} giorni</p>
            </div>
          </Flex>
        </div>
      }
      <Wrapper p={20}>
        <Flex justify={'space-between'}>
          <h4 style={{ color: grey[2] }}>Abbonamento</h4>
          <SubscriptionStatusCip status={subscription.status} />
        </Flex>
        <div>
          <div>
            <h4 style={{
              marginTop: 5,
              fontSize: 22
            }}>Abbonamento {subscription.interval === 'month' ? 'mensile' : 'annuale'}</h4>
            {subscription.extra_sales_points_number > 0 &&
              <p>+{subscription.extra_sales_points_number} Punt{subscription.extra_sales_points_number === 1 ? 'o' : 'i'} vendita
                extra</p>
            }
          </div>
          {subscription.cancel_at ?
            <p style={{ marginTop: 6, color: grey[2] }}>L'abbonamento terminerà
              il {dayjs(subscription.cancel_at * 1000).format('DD/MM/YY')} e non sarà rinnovato</p> :
            <p style={{ marginTop: 6, color: grey[2] }}>L'abbonamento sarà rinnovato automaticamente
              il {subscription.renewal_date}</p>
          }

          {subscription.cancel_at ?
            <Flex justify={'end'}>
              <Button onClick={() => {
                setAutoRenewModalOpen(true);
              }} type={'primary'} style={{
                marginTop: 10
              }}>Rinnova
                abbonamento</Button>
            </Flex>
            :
            <p onClick={() => {
              setCancelRenewModalOpen(true);
            }} style={{
              marginTop: 10,
              color: grey[2],
              textDecoration: 'underline',
              cursor: 'pointer'
            }}>Annulla rinnovo automatico</p>
          }

        </div>

        <div style={{ textAlign: 'right' }}>
          <Tooltip trigger={'click'}
                   title={!subscription.default_payment_method && 'Devi inserire un metodo di pagamento valido per poter cambiare il piano di abbonamento'}>
            <Button onClick={() => {
              !!subscription.default_payment_method && setChangePlanModalOpen(true);
            }}>Cambia abbonamento</Button>
          </Tooltip>
        </div>

      </Wrapper>
      <ConfirmModal
        open={cancelRenewModalOpen}
        setOpen={setCancelRenewModalOpen}
        danger={true}
        title={'Annulla Abbonamento'}
        buttonText={'Annulla abbonamento'}
        mutationOptions={toggleAutoRenewMutation}
      >
        <p>
          L'abbonamento sarà annullato al termine del periodo di fatturazione
          corrente.
        </p>
      </ConfirmModal>
      <ConfirmModal
        open={autoRenewModalOpen}
        setOpen={setAutoRenewModalOpen}
        danger={false}
        title={'Rinnova Abbonamento'}
        buttonText={'Rinnova abbonamento'}
        mutationOptions={toggleAutoRenewMutation}
      >
        <p>
          L'abbonamento sarà rinnovato automaticamente al termine del periodo di
          fatturazione corrente.
        </p>
      </ConfirmModal>
      <ChangePlanModal open={changePlanModalOpen} setChangePlanModal={setChangePlanModalOpen}
                       subscriptionInterval={subscription.interval} />
    </div>

  );
};
export default SubscriptionOverview;

function SubscriptionStatusCip({ status }: { status: Stripe.Subscription.Status }) {
  return (
    <>
      {status === 'canceled' ? (
        <p
          style={{
            padding: '3px 10px',
            backgroundColor: gray[0],
            borderRadius: 5,
            color: 'white',
            fontSize: 13,
            width: 'fit-content'
          }}
        >
          Annullato
        </p>
      ) : status === 'paused' ? (
        <p
          style={{
            padding: '3px 10px',
            backgroundColor: gray[1],
            borderRadius: 5,
            color: 'white',
            fontSize: 13,
            width: 'fit-content'
          }}
        >
          <PauseOutlined /> In Pausa
        </p>
      ) : status === 'trialing' ? (
        <p
          style={{
            padding: '3px 10px',
            backgroundColor: blue[1],
            borderRadius: 5,
            color: blue[4],
            fontSize: 13,
            fontWeight: 600,
            width: 'fit-content'
          }}
        >
          In prova
        </p>
      ) : (
        <p
          style={{
            padding: '3px 10px',
            backgroundColor: green[1],
            borderRadius: 5,
            color: green[6],
            fontSize: 13,
            fontWeight: 600,
            width: 'fit-content'
          }}
        >
          Attivo
        </p>
      )
      }
    </>
  );
}
