import dayjs from "dayjs";
import { endDate, startingDate, timeSpan } from "../src/routes/_authenticated/analytics";

export const itaMonthsOrder = [
  "gen",
  "feb",
  "mar",
  "apr",
  "mag",
  "giu",
  "lug",
  "ago",
  "set",
  "ott",
  "nov",
  "dic",
];
export const itaWeekDays = ['lun', 'mar', 'mer', 'gio', 'ven', 'sab', 'dom']
export const hoursOrder = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]

const allDays = [
    "30", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11",
    "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23",
    "24", "25", "26", "27", "28", "29"
];

export const dateFormats: any = {
    'year': '%b %y',
    'month': '%b %d',
    'week': '%a %d',
    'hours': '%b %d',
}

export const tickValues: any = {
    year: "every 2 month",
    'month': 'every 5 days',
    'week': 'every 1 days',
    'hours': 'every 2 days',
}


export function getAllDaysBetweenDates(start: any, end: any) {

    const days = [];

    let current = start;
    while (current.isBefore(end) || current.isSame(end, 'day')) {
        days.push(current.format('YYYY-MM-DD'));
        current = current.add(1, 'day');
    }

    return days;
}

export function getAllWeekDaysBetweenDates(start: any, end: any) {

    const days = [];

    let current = start;
    while (current.isBefore(end) || current.isSame(end, 'day')) {
        days.push(current.format('YYYY-MM-DD'));
        current = current.add(1, 'day');
    }

    return days;
}

export function getAllMonthsDaysBetweenDates(start: any, end: any) {

    const months = [];
    let currentMonth = start;

    while (currentMonth.isBefore(end) || currentMonth.isSame(end, 'month')) {
        months.push(currentMonth.format('YYYY-MM-DD'));
        currentMonth = currentMonth.add(1, 'month');
    }

    return months;
}

export function groupDatesBy(objects: object[], span: string) {

    let groupedBy: {key: string | number, data: any[]}[] = [];
    const allMonths = getAllMonthsDaysBetweenDates(startingDate.value, endDate.value)
    const allDays = getAllDaysBetweenDates(startingDate.value, endDate.value)
    const allWeekDays = getAllWeekDaysBetweenDates(startingDate.value, endDate.value)

    switch (span) {
        case 'year':
            groupedBy = allMonths.map((item)=>({key: item, data: []}))
            break;
        case 'month':
            groupedBy = allDays.map((item)=>({key: item, data: []}))
            break;
        case 'week':
            groupedBy = allWeekDays.map((item)=>({key: item, data: []}))
            break;
        case 'hours':
            groupedBy = hoursOrder.map((item)=>({key: item, data: []}))
            break;
    }

   objects.forEach((obj: any) => {
        const createdAt = dayjs(obj.created_at);

        if(timeSpan.value === 'year'){
            const formatted = createdAt.format('YYYY-MM')
            const index = groupedBy.findIndex((obj: any) => obj.key.includes(formatted));
            groupedBy[index]?.data?.push(obj);
        }else{
            const formatted = createdAt.format('YYYY-MM-DD')
            const index = groupedBy.findIndex((obj: any) => obj.key === formatted);
            groupedBy[index].data.push(obj);
        }
    });

    return groupedBy;
}

export function groupByHours(objects: any) {
    // Create an object to store grouped data
    const groupedByHours: any = {};

    // Iterate through each object in the array
    objects.forEach((obj: any) => {
        // Extract the month from the 'created_at' property
        const createdAt = dayjs(obj.created_at);
        const month = createdAt.locale('it').format('H');

        // If the month is not a key in the grouped object, create an array for it
        if (!groupedByHours[month]) {
            groupedByHours[month] = [];
        }

        // Push the current object to the array of the corresponding month
        groupedByHours[month].push(obj);
    });

    for (let i = 0; i < 24; i++) {
        const hour = i.toString();
        if (!groupedByHours[hour]) {
            groupedByHours[hour] = [];
        }
    }

    return groupedByHours;
}

export function sumPoints(inputArray: any[], type: number, order: 'year' | 'month' | 'hour' | 'week' = 'month') {
    return inputArray.map((item)=>{
       const somma =  item.data.reduce((sum: number, item: any) => {
            if (item.type === type) {
                return sum + Number(item.points);
            }
            return sum;
        }, 0);

       return {x: item.key, y: somma}
    })
}

export function countGoalOccurrences(obj: any) {
    const result: any = [];

    for (const key in obj) {
        if (obj.hasOwnProperty(key) && Array.isArray(obj[key])) {
            const counts: any = {};
            obj[key].forEach((item: any) => {
                if (item && typeof item === 'object' && item.hasOwnProperty('goal_name')) {
                    const goalName = item.goal_name;
                    if (goalName) {
                        counts[goalName] = (counts[goalName] || 0) + 1;
                    }
                }
            });
            result.push(counts);
        }
    }

    return result;
}

export function countOccurrences(inputArray: any) {
    // Result array to store the transformed data
    let resultArray = [];

    // Iterate through the input array
    for (let i = 0; i < inputArray.length; i++) {
        // Object to store the result for the current element
        let resultObject : any = {
            index: inputArray[i].key,
        };

        // Iterate through the data array of the current element
        for (let j = 0; j < inputArray[i].data.length; j++) {
            let goalName = inputArray[i].data[j].goal_name;

            // Check if the goal_name is already a property in the resultObject
            if (resultObject.hasOwnProperty(goalName)) {
                // Increment the count if the property already exists
                resultObject[goalName]++;
            } else {
                // Initialize the count to 1 if the property doesn't exist
                resultObject[goalName] = 1;
            }
        }

        // Add the resultObject to the resultArray
        resultArray.push(resultObject);
    }

    return resultArray;
}

interface GoalObject {
    goal_name: string;
}

interface InputObject {
    [key: string]: GoalObject[];
}

export function getDistinctGoalNames(dataArray: any) {
    const distinctGoalNames: any = [];

    dataArray.forEach((monthData: any) => {
        monthData.data.forEach((goalData: any) => {
            const goalName = goalData.goal_name;
            if (!distinctGoalNames.includes(goalName)) {
                distinctGoalNames.push(goalName);
            }
        });
    });

    return distinctGoalNames;
}

export function sumYValues(data: any) {
    // Use the reduce method to sum the 'y' values
     // Initialize the accumulator with 0
    return data.reduce((accumulator: any, currentValue: any) => {
        // Access the 'y' property and add it to the accumulator
        return accumulator + currentValue.y;
    }, 0);
}
