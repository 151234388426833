import ContainerBlock from './ContainerBlock';
import SingleLineChartTimeFormat from './charts/SingleLineChartTimeFormat';

import { groupDatesBy, sumPoints, sumYValues } from '../../utils/analytics';
import { endDate, startingDate, timeSpan } from '../../src/routes/_authenticated/analytics';
import { useSuspenseQuery } from '@tanstack/react-query';
import { transactionsQueryOptions } from '../../utils/queryOptions';

const AllocatedPointsTrend = ({ selectedSalesPointId }: { selectedSalesPointId: string }) => {

  const transactions = useSuspenseQuery(transactionsQueryOptions(endDate.value, startingDate.value, selectedSalesPointId));
  const groupedByDate = groupDatesBy(transactions.data, timeSpan.value);
  const summedPoints = sumPoints(groupedByDate, 0, timeSpan.value);

  return (
    <ContainerBlock total={sumYValues(summedPoints)} title={'Punti assegnati a clienti'}>
      <SingleLineChartTimeFormat data={summedPoints} />
    </ContainerBlock>
  );
};
export default AllocatedPointsTrend;
