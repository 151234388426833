import * as React from 'react'
import { createFileRoute, Outlet, redirect } from '@tanstack/react-router';
import { supabase } from '../../utils/supabase';
import PageLoader from '../../microcomponents/PageLoader';
import OnBoardLayout from '../../layouts/OnBoardLayout';
import posthog from 'posthog-js';

export const Route = createFileRoute('/_on-boarding')({
  component: RouteComponent,
  beforeLoad: async ({ context, location }) => {
    const { data: { session } } = await supabase.auth.getSession();
    if (!session) {
      throw redirect({ to: '/login' });
    }
    const { data: { user } } = await supabase.auth.getUser();

    if (!user || !user.email) {
      await supabase.auth.signOut();
      throw redirect({ to: '/login' });
    }

    if(location.pathname === '/update-password') {
      return {user: user}
    }

    //this must stay before the shop check because if the user is not a shop, he can update his password and became a shop
    if(location.pathname === '/update-password') {
      return {user: user}
    }

    const { data } = await supabase.from('shops').select('id').eq('id', user.id);
    if (data?.length === 1) {
      throw redirect({ to: '/analytics' });
    }
    if (user.confirmed_at === null && location.pathname !== '/confirm-email') {
      throw redirect({ to: '/confirm-email', search: { email: user.email } });
    }
    if(user.confirmed_at && location.pathname === '/confirm-email') {
      throw redirect({ to: '/on-boarding' });
    }

    posthog.identify(user.id, { email: user.email });

    return {user: user}
  },

  pendingComponent: PageLoader
})

function RouteComponent() {
  return <Outlet/>
}
