import {z} from 'zod'

export const QrCodePrefixes = z.enum(["0", "1", "2", "3"]);
export type QrCodePrefixesType = z.infer<typeof QrCodePrefixes>
export const QrCodeSchema = z.object({
    shop_id: z.string().uuid(),
    qr_code: z.string(),
    sales_point_id: z.string().uuid()
})
export type QrCode = z.infer<typeof QrCodeSchema>

export const GoalSchema = z.object({
    title: z.string(),
    number: z.number().max(5000).min(1)
});
export type GoalType = z.infer<typeof GoalSchema>
export const GoalsArraySchema = z.array(GoalSchema).min(1);
export type GoalsArrayType = z.infer<typeof GoalsArraySchema>

export const customerQrCodeTypeSchema = z.enum(['addPoints', 'goal'])
export type CustomerQrCodeType = z.infer<typeof customerQrCodeTypeSchema>
