import Stripe from 'stripe';
import { z } from 'zod';

export type SubscriptionStatus = Stripe.Subscription.Status
export const subscriptionStatuses = ['active', 'trialing', 'past_due', 'unpaid', 'incomplete', 'incomplete_expired', 'canceled', 'paused', 'incomplete_expired'] as const;

export type SubscriptionLookupKey = 'yearly' | 'monthly'
export const subscriptionLookupKeySchema = z.enum(['yearly', 'monthly']);

export const subscriptionInputValuesSchema = z.object({
  promoCode: z.string().optional(),
  subscriptionType: subscriptionLookupKeySchema
});

export type SubscriptionInputValues = z.infer<typeof subscriptionInputValuesSchema>

export type DiscountType = {
  id: string,
  coupon: {
    id: string,
    amount_off: number,
    name: string,
  }
}

export type PromoCodeAttrs = {
  trial_days: number,
  price_lookup_key: SubscriptionLookupKey,
  stripe_coupon_id: string,
  user_id: string | null,
  salesman_id: string | null,
  promo_type: string
  id: string,
  couponId: string,
}

export const PaymentMethodAttrsSchema = z.object({
  id: z.string(),
  type: z.string(),
  cardBrand: z.string().nullish(),
  last4: z.string().nullish()
});

type RawPaymentMethodAttrs = z.infer<typeof PaymentMethodAttrsSchema>

export type PaymentMethodAttrs = Omit<RawPaymentMethodAttrs, 'type'> & { type: Stripe.PaymentMethod.Type }

export const subscriptionAttrsSchema = z.object({
  id: z.string(),
  status: z.enum(subscriptionStatuses),
  default_payment_method: PaymentMethodAttrsSchema.optional(),
  trial_days_left: z.number().optional(),
  interval: z.enum(['year', 'month', 'day', 'week']),
  extra_sales_points_number: z.number(),
  cancel_at: z.number().nullish(),
  cancel_at_period_end: z.boolean(),
  renewal_date: z.string(),
  subscription_amount: z.number(),
  extra_sales_points_subscriptions_amount: z.number().optional()
});

export type SubscriptionAttrs = z.infer<typeof subscriptionAttrsSchema>


export const PromoSchema = z.object({
  code: z.string(),
  disclaimer_it: z.string(),
  title_it: z.string(),
  price_lookup_key: subscriptionLookupKeySchema,
  old_price: z.string(),
  new_price: z.string(),
  price_time_range_it: z.string(),
});

export type Promo = z.infer<typeof PromoSchema>

