import SimpleStatistic from './charts/SimpleStatistic';
import { useSuspenseQuery } from '@tanstack/react-query';
import { apiV1 } from '../../utils/apiClient';
import { notificationsCountQueryOptions } from '../../utils/queryOptions';


const TotalNotificationsSend = () => {

  const notificationsCount = useSuspenseQuery(notificationsCountQueryOptions());

  return <SimpleStatistic title={'Totale notifiche inviate'} value={notificationsCount.data} />;
};
export default TotalNotificationsSend;
