import { z } from 'zod';

export type CallToActionType = 'url' | 'call' | 'email' | 'whatsapp' | 'facebook' | 'instagram';

export const CallToActionSchema = z.object({
  type: z.enum(['url', 'call', 'email', 'whatsapp', 'instagram', 'facebook']),
  label: z.string(),
  value: z.string()
});

export type CallToAction = z.infer<typeof CallToActionSchema>

export const ctaValueInputLabels = {
  url: 'URL',
  call: 'Numero di telefono',
  email: 'Email',
  whatsapp: 'Numero di telefono',
  facebook: 'Facebook',
  instagram: 'Instagram'
}

export const ctaDefaultLabel = {
  url: 'Visita il sito',
  call: 'Chiama',
  email: 'Invia un\'email',
  'whatsapp': 'Invia un messaggio',
  facebook: 'Seguici su Facebook',
  instagram: 'Seguici su Instagram'
}

export const ctaValueValidation = {
  url: {schema: z.string().url(), errorMessage: 'Inserisci un URL valido'},
  call: {schema: z.string().regex(/^\+?[0-9]+$/, 'Inserisci un numero di telefono valido'), errorMessage: 'Inserisci un numero di telefono valido'},
  email: {schema: z.string().email(), errorMessage: 'Inserisci un\'email valida'},
  whatsapp: {schema: z.string().regex(/^\+?[0-9]+$/, 'Inserisci un numero di telefono valido'), errorMessage: 'Inserisci un numero di telefono valido'},
  facebook: {schema: z.string().regex(/^https?:\/\/(www\.)?facebook\.com\/.*/, 'Inserisci un URL di Facebook valido'), errorMessage: 'Inserisci un URL di Facebook valido'},
  instagram: {schema: z.string().regex(/^https?:\/\/(www\.)?instagram\.com\/.*/, 'Inserisci un URL di Facebook valido'), errorMessage: 'Inserisci un URL valido'}
}

export const ctaLinkPrefixes = {
  url: '',
  email: 'mailto:',
  call: 'tel:',
  whatsapp: 'https://wa.me/',
  facebook: '',
  instagram: ''
}
