import * as React from 'react';
import { createFileRoute, redirect, useNavigate } from '@tanstack/react-router';
import PageLoader from '../../../../microcomponents/PageLoader';
import { Badge, Button, DatePicker, Flex, Form, Input, message, Select, TimePicker } from 'antd';
import { useState } from 'react';
import { apiV1 } from '../../../../utils/apiClient';
import { supabase } from '../../../../utils/supabase';
import { compressImage } from '../../../../utils/imageCompression';
import { trackEvent } from '../../../../utils/Matomo/tracking_functions';
import {
  ArrowLeftOutlined, FacebookOutlined, InstagramOutlined,
  LinkOutlined,
  MailOutlined,
  PhoneOutlined, WhatsAppOutlined
} from '@ant-design/icons';
import ImageUploadInput from '../../../../components/ImageUploadInput';
import ConfirmButton from '../../../../components/ConfirmButton';
import PhonePreviewFrame from '../../../../components/PhonePreviewFrame';
import NotificationPreview from '../../../../components/NotificationPreview';
import useShopAttrs from '../../../../utils/hooks/useShopAttrs';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  CallToActionType, ctaDefaultLabel,
  ctaValueInputLabels, ctaValueValidation,
  NewNotification
} from '@next-is-free-monorepo/next-is-free-types';
import { blue } from '@ant-design/colors';

const { TextArea } = Input;

export const Route = createFileRoute('/_authenticated/notifications/new')({
  component: RouteComponent,
  pendingComponent: PageLoader
  /*loader: ctx => {
    if (!ctx.context.isVisible) {
      throw redirect({ to: '/notifications' });
    }
  }*/
});

function RouteComponent() {
  const navigate = useNavigate();
  const shopAttrs = useShopAttrs();
  const queryClient = useQueryClient();

  const [form] = Form.useForm();
  const [image, setImage] = useState();
  const [imagePreviewUrl, setImagePreviewUrl] = useState('');

  const title = Form.useWatch('title', form);
  const text = Form.useWatch('text', form);
  const ctaType: CallToActionType | 'no-cta' = Form.useWatch('cta-type', form);
  const ctaLabel: string = Form.useWatch('cta-label', form);
  const ctaValue: string = Form.useWatch('cta-value', form);

  function onFormChange(changedValues: any, values: any) {
    if (changedValues['cta-type']) {
      const ctaType: CallToActionType = changedValues['cta-type'];
      form.setFieldValue('cta-label', ctaDefaultLabel[ctaType]);
      form.setFieldValue('cta-value', '');
    }
  }


  async function sendNotificationFn(values: { title: string, text: string }) {

    const notification_id = crypto.randomUUID();

    if (image) {
      const compressedImage = await compressImage(image);
      const {
        data,
        error: uploadError
      } = await supabase.storage.from('main').upload(shopAttrs.data.id + '/' + notification_id + '.jpeg', compressedImage, { upsert: true });
      if (uploadError) {
        throw uploadError;
      }
      trackEvent('Notifications', 'Image Uploaded', values.title);
    }

    const body: NewNotification = {
      id: notification_id,
      title: values.title,
      text: values.text,
      image: !!image,
      business_name: shopAttrs.data.business_name,
      shop_latest_update_at: shopAttrs.data.latest_update_at ?? '',
      call_to_actions: ctaType === 'no-cta' ? null : [{
        type: ctaType,
        value: ctaValue,
        label: ctaLabel
      }]
    };

    await apiV1.post('/shop/notifications', body);

  }

  const send = useMutation({
    mutationKey: ['notifications', 'send'],
    mutationFn: sendNotificationFn,
    onSuccess: () => {
      message.success('Notifica inviata con successo');
      navigate({ to: '/notifications' });
      queryClient.invalidateQueries({ queryKey: ['notifications'] });
    }
  });

  return (
    <>
      <div style={{ marginBottom: 40 }}>
      </div>
      <div className={'customizePageW'}>
        <>
          <div style={{ width: '100%', maxWidth: 400 }}>
            <Button onClick={() => navigate({ to: '/notifications' })} style={{ color: 'grey', marginBottom: 30 }}
                    icon={<ArrowLeftOutlined />}>Indietro</Button>
            <h1>Crea una nuova notifica</h1>
            <Form onValuesChange={onFormChange} initialValues={{ 'cta-type': 'no-cta' }} form={form}
                  onFinish={send.mutate} layout={'vertical'}>
              <Form.Item label={'Immagine'}>
                <ImageUploadInput setImage={setImage} image={image} setImagePreviewUrl={setImagePreviewUrl}
                                  imagePreviewUrl={imagePreviewUrl} objectFit={'cover'} />
              </Form.Item>
              <Form.Item rules={[{ required: true, message: 'Le notifiche devono avere un titolo' }]} label={'Titolo'}
                         name={'title'}>
                <Input onKeyDown={e => e.key === 'Enter' ? e.preventDefault() : null} showCount maxLength={100} placeholder={'Titolo'} name={'title'} />
              </Form.Item>
              <Form.Item rules={[{ required: true, message: 'Le notifiche devono avere un testo' }]} label={'Testo'}
                         style={{ marginBottom: 30 }} name={'text'}>
                <TextArea showCount maxLength={2000} autoSize={{ minRows: 3, maxRows: 10 }}
                          placeholder={'Testo'}
                          name={'title'} />
              </Form.Item>
              <Form.Item name={'cta-type'}
                         tooltip={'Seleziona un\'azione che l\'utente può eseguire direttamente dalla notifica, come visitare un sito, chiamare un numero o aggiungere un evento al calendario. Questo aiuta a rendere la notifica più interattiva ed efficace.'}
                         label={<Flex gap={5}><p>Azione</p> <p style={{ color: blue[4], fontWeight: 200 }}>Novità!</p>
                         </Flex>}>
                <Select>
                  <Select.Option value="call"><PhoneOutlined /> Chiama</Select.Option>
                  <Select.Option value="whatsapp"><WhatsAppOutlined /> WhatsApp</Select.Option>
                  <Select.Option value="url"><LinkOutlined /> Link</Select.Option>
                  <Select.Option value="instagram"><InstagramOutlined /> Instagram</Select.Option>
                  <Select.Option value="facebook"><FacebookOutlined /> Facebook</Select.Option>
                  <Select.Option value="email"><MailOutlined /> Email</Select.Option>
                  <Select.Option value="no-cta" style={{ color: 'gray' }}>Nessuna</Select.Option>
                </Select>
              </Form.Item>
              {ctaType && ctaType !== 'no-cta' &&
                <Form.Item name={'cta-label'} rules={[{ required: true, message: 'Non può essere vuoto' }]}
                           label={'Testo bottone'} required>
                  <Input placeholder={ctaDefaultLabel[ctaType]} />
                </Form.Item>
              }
              {ctaType && ctaType !== 'no-cta' &&
                <Form.Item name={'cta-value'} rules={[{
                  validator: (_, value) => {
                    if(ctaValueValidation[ctaType].schema.safeParse(value).success) {
                      return Promise.resolve();
                    }else{
                      return Promise.reject(ctaValueValidation[ctaType].errorMessage);
                    }
                  }
                }]} label={ctaValueInputLabels[ctaType]} required>
                  <Input placeholder={''} />
                </Form.Item>
              }
              <Form.Item style={{ marginTop: 30 }}>
                <ConfirmButton loading={send.isPending} onConfirm={() => form.submit()} text={'Invia la notifica'}
                               message={'La notifica verrà inviata a tutti i tuoi clienti e non può essere modificata, vuoi continuare?'}
                               okText={'Invia'} />
              </Form.Item>
            </Form>
          </div>
          <div style={{ width: '100%', maxWidth: 350 }} className={'previewPhone'}>
              <PhonePreviewFrame>
                <NotificationPreview ctaValue={ctaValue} ctaType={ctaType} ctaLabel={ctaLabel} imageUrl={imagePreviewUrl} title={title}
                                     text={text} />
              </PhonePreviewFrame>
          </div>
        </>
      </div>
    </>);
}
