import { Card, Flex, Input } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { authGet } from '../utils/authCalls';
import { useDebouncedCallback } from 'use-debounce';
import { useEffect, useState } from 'react';
import { z } from 'zod';
import Loader from './Loader';
import { blue, gray, green, grey } from '@ant-design/colors';
import React from 'react';
import { apiV1 } from '../utils/apiClient';
import { Promo } from '@next-is-free-monorepo/next-is-free-types';

const SubscriptionSelector = ({
                                value = { promoCode: undefined, subscriptionType: 'yearly' },
                                onChange,
                                withoutFreeTrial,
                                withoutPromoCode
                              }: {
  value?: { promoCode: string | undefined, subscriptionType: 'yearly' | 'monthly' | undefined },
  onChange?: any,
  withoutFreeTrial?: boolean,
  withoutPromoCode?: boolean,
}) => {
  const [valid, setValid] = useState<boolean | undefined>(false);
  const [loading, setLoading] = useState(false);
  const [promoPlan, setPromoPlan] = useState<any>({});

  function checkCode(code: string) {
    if (!code) {
      setLoading(false);
      setValid(undefined);
      onChange({ promoCode: undefined, subscriptionType: 'yearly' });
      setPromoPlan({});
      return;
    }
    setPromoPlan({});
    setValid(false);
    onChange({ promoCode: code, subscriptionType: undefined });
    setLoading(true);
    searchCode(code);
  }

  const searchCode = useDebouncedCallback(async (text: string) => {

    try {
      await z.string().min(4).parseAsync(text);
      const promoCode = await apiV1.get<Promo>('/promos/' + text);
      setPromoPlan(promoCode);
      setValid(true);
      onChange({ promoCode: text, subscriptionType: promoCode.price_lookup_key });
      setLoading(false);
    } catch (e) {
      setValid(false);
      setLoading(false);
      setPromoPlan({});
    }
  }, 500);

  useEffect(() => {
    if (value.promoCode) {
      checkCode(value.promoCode);
    }
  }, []);

  return (
    <div style={{ marginBottom: 5 }}>
      {!withoutPromoCode &&
        <Input value={value.promoCode} style={{ borderColor: valid ? green[5] : undefined }}
               onChange={(e) => checkCode(e.target.value)} placeholder={'Codice sconto'}
               suffix={loading ? <Loader /> : !value.promoCode ? <div></div> : valid ?
                 <CheckOutlined style={{ color: green[6] }} /> : valid === false ?
                   <CloseOutlined style={{ color: 'red' }} /> : <div></div>} /> //must be an empty div otherwise it will lose focus
      }
      <Flex gap={10} style={{ marginTop: 10, flexDirection: 'column' }}>
        {promoPlan?.title_it &&
          <SubscriptionCard price_time_range={promoPlan?.price_time_range_it} subscriptionType={value.subscriptionType}
                            disclaimer={promoPlan?.disclaimer_it}
                            setSubscriptionType={() => undefined} oldPrice={promoPlan?.old_price}
                            price={promoPlan?.new_price}
                            title={promoPlan?.title_it} price_lookup_key={promoPlan.price_lookup_key} />
        }
        <SubscriptionCard price_time_range={'Mese + Iva'} disabled={!!value.promoCode}
                          subscriptionType={value?.subscriptionType}
                          disclaimer={`Fatturato annualmente ${!withoutFreeTrial ? 'dopo 30 giorni di prova gratuita' : ''}`}
                          setSubscriptionType={(type) => {
                            onChange({ ...value, subscriptionType: type });
                          }} oldPrice={'49'} price={'39'}
                          title={'Abbonamento Annuale'} price_lookup_key={'yearly'} badge={'Risparmia il 20%'} />
        <SubscriptionCard price_time_range={'Mese + Iva'} disabled={!!value.promoCode}
                          subscriptionType={value?.subscriptionType}
                          disclaimer={`Fatturato mensilmente ${!withoutFreeTrial ? 'dopo 30 giorni di prova gratuita' : ''}`}
                          setSubscriptionType={(type) => {
                            onChange({ ...value, subscriptionType: type });
                          }} price={'49'} title={'Abbonamento Mensile'}
                          price_lookup_key={'monthly'} />
      </Flex>
    </div>
  );
};
export default SubscriptionSelector;

interface SubscriptionCardProps {
  subscriptionType: 'yearly' | 'monthly' | undefined,
  setSubscriptionType: (value: 'yearly' | 'monthly') => void,
  oldPrice?: string,
  price: string,
  disclaimer?: string,
  title: string,
  price_lookup_key: 'yearly' | 'monthly',
  badge?: string
  price_time_range: string,
  disabled?: boolean
}

function SubscriptionCard({
                            subscriptionType,
                            setSubscriptionType,
                            oldPrice,
                            price,
                            disclaimer,
                            title,
                            badge,
                            price_lookup_key,
                            disabled,
                            price_time_range
                          }: SubscriptionCardProps) {
  return (
    <Card
      size={'small'}
      onClick={() => {
        !disabled && setSubscriptionType(price_lookup_key);
      }}
      style={{
        flex: 1,
        cursor: 'pointer',
        backgroundColor: subscriptionType === price_lookup_key && !disabled ? blue[0] : '#f7f7f7',
        borderColor:
          subscriptionType === price_lookup_key && !disabled ? blue[2] : '#f7f7f7'
      }}
    >
      {badge &&
        <div
          style={{
            backgroundColor: disabled ? grey[0] : green[1],
            width: 'fit-content',
            position: 'absolute',
            right: 10,
            top: 10,
            padding: '1px 8px',
            color: disabled ? 'white' : green[7],
            fontSize: 13,
            fontWeight: 500,
            borderRadius: 30,
            border: 'solid 1px',
            borderColor: disabled ? grey[0] : green[2]
          }}
        >
          {badge}
        </div>
      }

      <Flex align={'center'} gap={10}>
        <Flex align={'center'} justify={'center'}
              style={{ minWidth: 20, height: 20, backgroundColor: 'white', borderRadius: 20 }}>
          <div style={{
            width: 10,
            height: 10,
            backgroundColor: subscriptionType === price_lookup_key && !disabled ? blue[5] : 'white',
            borderRadius: 20
          }} />
        </Flex>
        <div>
          <h3
            style={{
              fontSize: 15,
              color: disabled ? grey[0] : subscriptionType === price_lookup_key ? blue[6] : 'black'
            }}
          >
            {title}
          </h3>
          <p style={{ color: disabled ? grey[3] : 'black' }}>
            <b style={{ fontSize: 16 }}>
              {oldPrice && <s style={{ color: gray[3] }}>{oldPrice}€</s>} {price}€
            </b>
            /{price_time_range}
          </p>
          <p
            style={{
              fontSize: 13,
              color: gray[5],
              marginTop: 5,
              lineHeight: 1
            }}
          >
            {disclaimer}
          </p>
        </div>
      </Flex>
    </Card>
  );
}
