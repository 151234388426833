import { Button, Card, Dropdown, Modal, Space } from 'antd';

import { DeleteOutlined, DownOutlined, EllipsisOutlined, SmileOutlined } from '@ant-design/icons';
import { gray } from '@ant-design/colors';
import React, { useState } from 'react';
import { supabase } from '../utils/supabase';
import Wrapper from './Wrapper';
import { bucketUrl } from '../utils/urls';
import { IconDots } from '@tabler/icons-react';
import { Notification } from '@next-is-free-monorepo/next-is-free-types';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { apiV1 } from '../utils/apiClient';
import useShopAttrs from '../utils/hooks/useShopAttrs';


const NotificationCard = ({ item }: { item: Notification }) => {

  const [modalOpen, setModalOpen] = useState(false);
  const queryClient = useQueryClient();
  const shopsAttrs = useShopAttrs()

  async function deleteNotificationFunction() {
    await apiV1.delete(`/shop/notifications/${item.id}`);
    if (item.image) {
      const { error: deleteImageError } = await supabase
        .storage
        .from('main')
        .remove([shopsAttrs.data.id + '/' + item.id + '.jpeg']);

      if (deleteImageError) {
        throw deleteImageError;
      }
    }
    await queryClient.invalidateQueries({ queryKey: ['notifications'] });
    setModalOpen(false);
  }

  const deleteNotification = useMutation({
    mutationKey: ['delete-notification'],
    mutationFn: async () => await deleteNotificationFunction()
  });


  const image = item.image ? bucketUrl + shopsAttrs.data.id + '/' + item.id + '.jpeg' : bucketUrl + shopsAttrs.data.id + '/logo.png';


  const items = [
    {
      key: '1',
      label: (
        <a onClick={() => setModalOpen(true)}>
          Elimina
        </a>
      ),
      danger: true,
      icon: <DeleteOutlined />
    }

  ];


  return (
    <>
      <Modal centered open={modalOpen} onCancel={() => setModalOpen(false)} okText={'Elimina'}
             cancelText={'Annulla'} okButtonProps={{ danger: true, loading: deleteNotification.isPending, disabled: deleteNotification.isPending }}
             cancelButtonProps={{ disabled: deleteNotification.isPending, loading: deleteNotification.isPending }} onOk={()=>deleteNotification.mutate()}>
        <h3>Vuoi davvero eliminare questa notifica?</h3>
        <p>I tuoi clienti non saranno più in grado di vederla. Questa azione è irreversibile</p>
      </Modal>
      <Wrapper style={{ width: '100%', maxWidth: 450, overflow: 'hidden' }}>
        <div style={{ display: 'flex', gap: 10 }}>
          <img alt={'notification'} style={{ objectFit: 'cover', borderRadius: 10 }} src={image} width={80}
               height={80} />
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}><p style={{
            margin: 0,
            fontSize: 12,
            color: gray[0],
            fontWeight: 600
          }}>{new Date(item.created_at).toLocaleDateString('en-UK')}</p><h3
            style={{ margin: 0 }}>{item.title}</h3></div>
        </div>
        <p style={{ color: gray[3], marginTop: 10 }}>{item.text}</p>
        <div style={{ textAlign: 'right' }}>
          <Dropdown menu={{ items }}>
            <Button style={{ width: 30, height: 30, padding: 0 }}><IconDots size={20} /></Button>
          </Dropdown>
        </div>

      </Wrapper></>);
};
export default NotificationCard;
