import {Card, Statistic, Tooltip} from "antd";
import CountUp from "react-countup";
import {gray} from "@ant-design/colors";
import SkeletonInput from "antd/es/skeleton/Input";
import {COLORS} from "../../../utils/colors";
import Wrapper from "../../Wrapper";
import { Suspense } from 'react';

interface SimpleStatisticProps {
title: string,
    value: number
    suffix?: string
    valueStyle?: any
    tooltipText?: string
    noData?: boolean
    disableFormatter?: boolean
}


const SimpleStatistic = ({title, value, suffix = '', valueStyle = {}, tooltipText = '', noData, disableFormatter}: SimpleStatisticProps) => {

/*    const formatter: any = (value: number) => <CountUp duration={0} preserveValue={true} decimal={','} end={value}/>;*/

  return (
      <Wrapper style={{padding: 20}}>
        <Tooltip title={tooltipText} placement={'bottomLeft'}>
          {noData ? <div>
            <p style={{color: gray[2], marginBottom: 10}}>{title}</p>
            <p style={{color: gray[2], fontWeight: 600}}>Dati insufficienti</p>
          </div> :
            <Statistic
            precision={0}
            title={title}
            value={value}
            suffix={suffix}
            valueStyle={valueStyle}
          />}
        </Tooltip>
      </Wrapper>
  );
};
export default SimpleStatistic;
