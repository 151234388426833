import ContainerBlock from "./ContainerBlock";
import SingleLineChartTimeFormat from "./charts/SingleLineChartTimeFormat";
import { groupDatesBy, sumYValues } from '../../utils/analytics';
import { useSuspenseQuery } from '@tanstack/react-query';
import { endDate, startingDate, timeSpan } from '../../src/routes/_authenticated/analytics';
import { customersQueryOptions } from '../../utils/queryOptions';

const CustomersNumberTrend = () => {

  const customers = useSuspenseQuery(customersQueryOptions(startingDate.value, endDate.value));

  const groupedCustomers = groupDatesBy(customers.data, timeSpan.value);

  const data = groupedCustomers.map((item: any)=>{
    return({x: item.key, y: item?.data?.length || 0})
  })

  return (
    <ContainerBlock total={sumYValues(data)} title={"Andamento numero clienti"}>
      <SingleLineChartTimeFormat data={data}/>
    </ContainerBlock>
  );
};
export default CustomersNumberTrend;
