import { z } from 'zod';

export const checkoutDataSchema = z.object({
  subscription_id: z.string(),
  client_secret: z.string(),
  interval: z.enum(['year', 'month', 'day', 'week']),
  subscription_amount: z.number(),
  invoice: z.object({
    discount_amount: z.number(),
    total_excluding_tax: z.number(),
    total: z.number(),
  }),
  extra_sales_points_subscriptions_amount: z.number().nullish(),
  extra_sales_points_number: z.number(),

})

export type CheckoutData = z.infer<typeof checkoutDataSchema>;
