import { createRootRouteWithContext, Outlet } from '@tanstack/react-router';
import { TanStackRouterDevtools } from '@tanstack/router-devtools';
import type { QueryClient } from '@tanstack/react-query';
import useShopAttrs from '../../utils/hooks/useShopAttrs';
import NotFound from '../../components/NotFound';

type RouterContext = {
/*  useShopAttrs: ReturnType<typeof useShopAttrs>*/
  queryClient: QueryClient
}

export const Route = createRootRouteWithContext<RouterContext>()({
  component: () => (
    <>
      <Outlet />
    </>
  ),
  notFoundComponent: props => <NotFound/>
});
