import { z } from 'zod';
import { AddressValueSchema } from './Inputs';
import { GoalSchema } from './QrCodes';
import { subscriptionLookupKeySchema, SubscriptionStatus, subscriptionStatuses } from './Subscriptions';
import { salesPointBaseSchema } from './SalesPoints';




export const SubscriptionsEnum = z.enum(['yearly', 'monthly']);

export type SubscriptionsType = z.infer<typeof SubscriptionsEnum>;

export const CreateShopSchema = z.object({
  id: z.string().uuid(),
  business_name: z.string().min(3),
  legal_name: z.string(),
  business_category: z.string().uuid(),
  vat: z.string().length(11),
  extraSalesPoints: z.array(salesPointBaseSchema),
  mainSalesPointAddress: AddressValueSchema,
  mainSalesPointName: z.string().min(2),
  subscription: z.object({ subscriptionType: SubscriptionsEnum, promoCode: z.string().optional() }),
  billing_address: AddressValueSchema,
  sdi_code: z.string().min(1)
});

export const createShopApiResultSchema = z.object({
  next: z.literal('toPayment'),
  subscriptionSummary: z.object({ invoice: z.object({
      total_before_discount: z.number(),
      total_before_discount_excluding_tax: z.number().nullish(),
      tax_amount: z.number().nullish(),
      total: z.number(),
      total_excluding_tax: z.number().nullish(),
      discount_amount: z.number().nullish()
    }),
    client_secret: z.string(),
    status: z.enum(subscriptionStatuses),
    extra_sales_points_number: z.number(),
    subscription_amount: z.number(),
    extra_sales_points_subscriptions_amount: z.number(),
    interval: z.enum(['year', 'month', 'day', 'week'])
})}).or(z.object({ next: z.literal('toDashboard') }));

export type CreateShopApiResult = z.infer<typeof createShopApiResultSchema>;

export type CreateShopType = z.infer<typeof CreateShopSchema>;

export const ShopPreviewSchema = z.object({
  id: z.string().uuid(),
  business_name: z.string(),
  latest_update_at: z.string().date(),
  brand_color: z.string()
});
export type ShopPreview = z.infer<typeof ShopPreviewSchema>;

export const UserShopPreviewSchema = ShopPreviewSchema.extend({notifications_allowed: z.boolean()})
export type UserShopPreview = z.infer<typeof UserShopPreviewSchema>;

export const UserShopSchema = z.object({
  user_id: z.string().uuid(),
  shop_id: z.string().uuid(),
  created_at: z.string().date(),
  points: z.number(),
  latest_notification_open: z.string().date(),
  notifications_allowed: z.boolean(),
  goals: z.array(GoalSchema),
  category_it: z.string(),
  just_added: z.boolean().optional(),
  business_name: z.string(),
  latest_update_at: z.string().date(),
  brand_color: z.string()
});
export type UserShop = z.infer<typeof UserShopSchema>;

export const ShopAttrsSchema = z.object({
  id: z.string().uuid(),
  business_name: z.string(),
  latest_update_at: z.string().nullish(),
  onboarded: z.literal(true),
  completed: z.boolean(),
  public: z.boolean(),
  isVisible: z.boolean(),
  business_category: z.string().uuid(),
  vat: z.string().length(11),
  subscription_status: z.enum(['active', 'incomplete', 'incomplete_expired', 'trialing', 'unpaid', 'canceled', 'past_due', 'paused']).optional(),
  isSubscriptionValid: z.boolean()
})

export type ShopAttrs = z.infer<typeof ShopAttrsSchema>
