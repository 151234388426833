import { z } from 'zod';
import { AddressValueSchema } from './Inputs';

export const salesPointBaseSchema = z.object({
  name: z.string().min(3),
  address: AddressValueSchema
});

export type SalesPointBase = z.infer<typeof salesPointBaseSchema>;

export const SalesPointPreviewSchema = salesPointBaseSchema.extend({
  id: z.string().uuid(),
  full_address: z.string(),
  is_default: z.boolean(),
  associated_qrcode: z.string().uuid().nullish()
});

export type SalesPointPreview = z.infer<typeof SalesPointPreviewSchema>

export const SalesPointSchema = z.object({
  shop_id: z.string().uuid(),
  name: z.string().min(3),
  full_address: z.string(),
  country: z.literal('IT'),
  lon: z.number(),
  lat: z.number(),
  stripe_subscription_id: z.string().uuid(),
  is_default: z.boolean(),
  google_position_id: z.string().uuid(),
  manual_address: z.boolean()
});

export type SalesPoint = z.infer<typeof SalesPointSchema>;

export const SalesPointAttributesSchema = z.object({
  id: z.string().uuid(),
  name: z.string().min(3),
  full_address: z.string(),
  shop_id: z.string().uuid(),
  is_default: z.boolean(),
  business_name: z.string(),
  isVisible: z.boolean(),
  latest_update_at: z.string().nullish(),
});

export type SalesPointAttrs = z.infer<typeof SalesPointAttributesSchema>;
