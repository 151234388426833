import SimpleStatistic from './charts/SimpleStatistic';
import { useSuspenseQuery } from '@tanstack/react-query';
import { customersCountQueryOptions } from '../../utils/queryOptions';


const TotalCustomersNumber = () => {

  const customers = useSuspenseQuery(customersCountQueryOptions());

  return (<SimpleStatistic title={'Totale clienti'} value={customers.data} />);
};
export default TotalCustomersNumber;
