import { Locales } from '../types/types';

export type UIErrorI18nKey = 'errorNotFound' | 'errorUnauthorized' | 'insufficientPoints' | 'userIsShop' | 'invalidQrCode' | 'somethingWentWrong' | 'invalidPromoCode';

type TranslationResources = {
  [lang in Locales]: {
    [key in UIErrorI18nKey]: string;
  };
}

export const errorsTranslations : TranslationResources = {
  en: {
    somethingWentWrong: 'Ops, Something went wrong',
    invalidQrCode: 'Invalid Qr code',
    userIsShop: 'Impossibile eliminare questo profilo dall\'app perché l\'email è associata ad un negozio, accedi alla dashboard per gestire il tuo profilo',
    insufficientPoints: 'Insufficient points',
    errorNotFound: 'Not Found',
    errorUnauthorized: 'Unauthorized',
    invalidPromoCode: 'Invalid promo code'
  },
  it: {
    somethingWentWrong: 'Ops, Qualcosa è andato storto',
    invalidQrCode: 'Qr code non valido',
    userIsShop: 'Unable to delete this profile from the app because the email is associated with a store, log in to the dashboard to manage your profile',
    insufficientPoints: 'Punti non sufficienti',
    errorNotFound: 'ciao',
    errorUnauthorized: 'ciao',
    invalidPromoCode: 'Codice promozionale non valido'
  }
}
