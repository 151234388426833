import {Button, Form, Input, Select} from "antd";
import PromoCodeInput from "../SubscriptionInput";
import AddressFormItem from "../AddressFormItem";
import React from "react";
import {validatePartitaIVA} from "../../utils/validatePartitaIVA";

import {z} from "zod";
import {Simulate} from "react-dom/test-utils";
import error = Simulate.error;
import posthog from 'posthog-js';


interface Step3Props {
    onSubmit: () => void,
    setFormValues: (values: any) => void
    formValues: any
}

const Step3 = ({onSubmit, setFormValues, formValues}: Step3Props) => {
    const [form] = Form.useForm();

    async function onFinish(values: any) {
        posthog.capture('onboarding_step_3_completed', values)
        setFormValues({...values})
        onSubmit()
    }


    return (
        <div data-matomo-form={true} data-matomo-name="onBoarding">
            <Form onValuesChange={(_, allFields) => {
                setFormValues({...allFields})
            }} initialValues={formValues} form={form} onFinish={onFinish} style={{maxWidth: '400px', margin: 'auto'}}
                  layout={'vertical'}>

                <Form.Item rules={[
                    {
                        required: true,
                        message: "Inserisci il nome o la ragione sociale",
                    }
                ]} required={false} label={'Nome o ragione sociale'} name={'legal_name'}>
                    <Input placeholder={'Nome o ragione sociale'}/>
                </Form.Item>
                <Form.Item validateDebounce={2000} hasFeedback rules={[
                    {
                        validator: (_, value) => {
                            if (validatePartitaIVA(value)) {
                                return Promise.resolve()
                            } else {
                                return Promise.reject('Partita Iva non corretta')
                            }
                        }
                    }
                ]}
                           required={false}
                           name={"vat"} label={'Partita IVA'}>
                    <Input placeholder={'Partita IVA'}/>
                </Form.Item>
                <AddressFormItem name={'billing_address'} label={'Indirizzo di fatturazione'} onlyManual form={form}/>
                <Form.Item rules={[
                    {
                        required: true,
                        message: "Inserisci il codice destinatario",
                    }
                ]}
                           required={false}
                           name={"sdi_code"} label={'Codice destinatario SDI'}>
                    <Input placeholder={'Codice Univoco'}/>
                </Form.Item>
                <Form.Item rules={[
                    {
                        validator: (_, value) => {
                            try {
                               /* CreateShopSchema.pick({subscription: true}).parse({subscription: value})*/
                                return Promise.resolve()
                            } catch (err) {
                                return Promise.reject('Codice sconto non valido')
                            }
                        }
                    }
                ]} style={{marginTop: 30}} name={'subscription'} label={'Codice sconto'}>
                    {/*@ts-ignore*/}
                    <PromoCodeInput/>
                </Form.Item>
                <Button type={'primary'} size={'large'}
                        style={{width: '100%', marginTop: 20, borderRadius: 30, height: 43}}
                        htmlType={'submit'}>Continua</Button>
            </Form>
        </div>);
}
export default Step3;
