import ContainerBlock from "./ContainerBlock";
import {
        countOccurrences,
    getDistinctGoalNames, groupDatesBy,
} from "../../utils/analytics";
import BarsChart from "./charts/BarsChart";

import { endDate, startingDate, timeSpan } from '../../src/routes/_authenticated/analytics';
import { useSuspenseQuery } from '@tanstack/react-query';
import { transactionsQueryOptions } from '../../utils/queryOptions';

const GoalsRank = ({ selectedSalesPointId }:{selectedSalesPointId: string} ) => {

  const transactions = useSuspenseQuery(transactionsQueryOptions(endDate.value, startingDate.value, selectedSalesPointId));

  const promotions = transactions.data.filter((item) => item.type === 1);
  const groupedPromotions = groupDatesBy(promotions, timeSpan.value)

  return (
    <ContainerBlock title={"Promozioni più usate"}>
      <BarsChart
        data={countOccurrences(groupedPromotions)}
        keys={getDistinctGoalNames(groupedPromotions)}
      />
    </ContainerBlock>
  );
};
export default GoalsRank;
