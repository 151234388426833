import Summary from '../Summary';
import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Alert, Button, message } from 'antd';
import { useState } from 'react';
import { apiV1 } from '../../utils/apiClient';
import React from 'react';
import { CheckoutData } from '@next-is-free-monorepo/next-is-free-types';
import { useSuspenseQuery } from '@tanstack/react-query';
import { checkoutQueryOptions } from '../../utils/queryOptions';
import { red } from '@ant-design/colors';
import * as Sentry from '@sentry/react'
import posthog from 'posthog-js';

export interface PaymentFormProps {
  clientSecret: string
  subscriptionInterval: 'month' | 'year',
  subscriptionAmount: number
  extraSalesPointUnitAmount: number
  extraSalesPointsNumber: number
}


const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY);

const PaymentForm = (props: CheckoutData) => {



  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (event: any) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    setLoading(true);

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: 'https://app.nextisfree.io'
      }
    });

    if (result.error) {
      message.error(result.error.message);
      Sentry.captureException(result.error.message);
      posthog.capture('payment_failed', {error: result.error.message})
      setError(result?.error?.message || 'Errore durante il pagamento');
    } else {
      posthog.capture('payment_success')
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
    setLoading(false);
  };

  async function cancelSubscription() {
    setLoading(true);
    await apiV1.delete(`/shop/subscriptions/cancel-immediately/${props.subscription_id}`);
    window.location.reload();
  }

  return (

    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <Summary {...props} />
      {error && <Alert message={error} style={{color: red[6], marginTop: 20}} type={'error'}/>}
      <Button loading={loading} disabled={loading} htmlType={'submit'} size={'large'} type={'primary'}
              style={{ borderRadius: 30, marginTop: 20, width: '100%' }}>Acquista</Button>
      <Button onClick={cancelSubscription} loading={loading} disabled={loading} type={'text'}
              style={{ width: '100%', marginTop: 10 }}>Annulla abbonamento</Button>
    </form>
  );
};

export default () => {

  const checkout = useSuspenseQuery(checkoutQueryOptions())

  const options = {
    // passing the client secret obtained from the server
    clientSecret: checkout.data.client_secret,
  };
  return (
    <div>
      <Elements stripe={stripePromise} options={options}>
        <PaymentForm {...checkout.data} />
      </Elements>
    </div>
  );
};
