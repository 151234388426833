import { useQueryClient, useSuspenseQuery } from '@tanstack/react-query';
import { apiV1 } from '../apiClient';
import { ShopAttrs } from '@next-is-free-monorepo/next-is-free-types';

const useShopAttrs = () => {
  const queryClient = useQueryClient();
  const shopAttrs = useSuspenseQuery({
    queryKey: ['shopAttrs'],
    queryFn: async () => await apiV1.get<ShopAttrs>('/shop/attributes'),
    staleTime: Infinity
  })

  async function invalidate() {
    await queryClient.invalidateQueries({ queryKey: ['shopAttrs'] });
  }

  async function setQueryData(shopAttrs: ShopAttrs) {
    await queryClient.setQueryData(['shopAttrs'], () => {
      return shopAttrs;
    });
  }

  return ({ data: shopAttrs.data, invalidate, setQueryData });
};
export default useShopAttrs;
