import { ResponsiveLine } from "@nivo/line";
import { orange } from "@ant-design/colors";
import {timeSpan} from "../../../src/routes/_authenticated/analytics";
import {dateFormats, tickValues} from "../../../utils/analytics";
import {COLORS} from "../../../utils/colors";

const SingleLineChartTimeFormat = ({data}: {data: any[]}) => {

    const smallest = findSmallestY(data)

    const nextData = [{id: Math.random().toString(), data: data}]

    function findSmallestY(array: any) {
        if (!Array.isArray(array) || array.length === 0) {
            // Handle invalid input or empty array
            return undefined;
        }

        // Initialize with the first element's 'y' value
        let smallestY = array[0].y;

        // Loop through the array to find the smallest 'y' value
        for (let i = 1; i < array.length; i++) {
            if (array[i].y < smallestY) {
                smallestY = array[i].y;
            }
        }

        return smallestY;
    }

  return (
    <ResponsiveLine
      data={nextData}
      margin={{ top: 50, right: 25, bottom: 24, left: 15 }}
      xFormat="time:%d-%m-%Y"
      xScale={{
          format: '%Y-%m-%d',
          precision: 'day',
          type: 'time',
          useUTC: false
      }}
      yScale={{
        type: "linear",
        min: "auto",
        max: "auto",
        stacked: false,
        reverse: false,
      }}
      yFormat=" >-.2f"
      curve="monotoneX"
      axisTop={null}
      axisRight={null}
      axisLeft={null}
      axisBottom={{
          format: dateFormats[timeSpan.value],
          tickValues: tickValues[timeSpan.value]
      }}
      enableGridX={false}
      colors={[COLORS.primary]}
      lineWidth={2}
      pointSize={5}
      pointColor={{ theme: "background" }}
      pointBorderWidth={2}
      pointBorderColor={{ from: "serieColor" }}
      enablePointLabel={true}
      pointLabel="data.y"
      pointLabelYOffset={-10}
      enableArea={true}
      areaOpacity={0.2}
      useMesh={true}
      defs={[
        {
          id: "gradientC",
          type: "linearGradient",
          colors: [
            { offset: 0, color: COLORS.primary },
            { offset: 100, color: "#ffffff" },
          ],
        },
      ]}
      // 2. defining rules to apply those gradients
      fill={[{ match: "*", id: "gradientC" }]}
      legends={[]}
    areaBaselineValue={smallest}
    />
  );
};
export default SingleLineChartTimeFormat;
