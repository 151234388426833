import { CSSProperties, ReactNode, useEffect, useState } from 'react';
import { COLORS } from '../utils/colors';
import '../styles/components/wrapper.sass';


const Wrapper = ({ children, style, p = 20, mt = 0, mh = 'auto', disabled = false, loading = false }: {
  children?: ReactNode,
  style?: CSSProperties,
  p?: number,
  mt?: number,
  mh?: string | number,
  disabled?: boolean,
  loading?: boolean
}) => {

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true); // Trigger fade-in on mount
  }, []);


/*  if (loading) {
    return (
      <div className={'skeleton'} style={{
        backgroundColor: disabled ? COLORS.veryLightGray : 'transparent',
        padding: p,
        marginTop: mt,
        border: `1px solid ${COLORS.lightGray}`,
        borderRadius: 10,
        minHeight: mh,
        opacity: isVisible ? 1 : 0,
        transition: 'opacity 0.3s ease-in-out',
        ...style
      }}/>
    );
  }*/

  let className = `wrapper`;
  if(disabled){
    className += ' disabled';
  }
  if(loading){
    className += ' loading';
  }

  return(
    <div className={className} style={{padding: p, marginTop: mt, ...style}}>
      {children}
    </div>
  )

};
export default Wrapper;
