import { Button, message, Space } from 'antd';
import { red } from '@ant-design/colors';
import ConfirmModal from '../ConfirmModal';
import { useState } from 'react';
import { apiV1 } from '../../utils/apiClient';
import { supabase } from '../../utils/supabase';
import { useNavigate } from '@tanstack/react-router';
import { signOut } from '../../utils/signOut';

const DangerZone = () => {

  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const navigate = useNavigate();

  const deleteShopMutation = {
    mutationKey: ['shop', 'delete'],
    mutationFn: async () => await apiV1.delete('/shop'),
    onSuccess: () => {
      message.success('Profilo eliminato con successo');
      signOut();
      navigate({ to: '/login' });
    }
  };

  /*async function deleteShop() {
    try{
      await apiV1.delete('/shop');
      message.success("Profilo eliminato con successo");
      window.location.reload()
    }catch (e) {
      console.log(e);
    }
  }*/

  return (
    <Space
      size={'middle'}
      direction={'vertical'}
      style={{
        border: '1px solid',
        borderColor: red[2],
        borderRadius: 20,
        padding: 20,
        width: '100%',
        marginTop: 70
      }}
    >
      <Button
        onClick={signOut}
        type={'primary'}
        size={'large'}
        style={{ width: '100%' }}
        danger
      >
        Log out
      </Button>
      <Button
        onClick={() => setDeleteConfirm(true)}
        size={'large'}
        style={{ width: '100%', backgroundColor: 'white' }}
        danger
      >
        Elimina profilo
      </Button>
      <ConfirmModal
        open={deleteConfirm}
        setOpen={setDeleteConfirm}
        danger={true}
        title={'Attenzione!'}
        buttonText={'Elimina definitivamente'}
        mutationOptions={deleteShopMutation}
      >
        <p>
          Se procedi tutti i dati del
          tuo account verranno eliminati <b>in modo irreversibile</b>. Gli abbonamenti
          in corso saranno annullati all'istante senza la possibilità di
          rinnovarli. Vuoi procedere?
        </p>
      </ConfirmModal>
    </Space>
  );
};
export default DangerZone;
