import * as React from 'react';
import { createFileRoute, Outlet, redirect } from '@tanstack/react-router';
import AuthLayout from '../../layouts/AuthLayout';
import { supabase } from '../../utils/supabase';
import PageLoader from '../../microcomponents/PageLoader';
import { apiV1 } from '../../utils/apiClient';
import { ShopAttrs } from '@next-is-free-monorepo/next-is-free-types';
import posthog from 'posthog-js';

export const Route = createFileRoute('/_authenticated')({
  component: RouteComponent,
  beforeLoad: async ({ context, location }) => {

    //Check if there is a session
    const { data: { session } } = await supabase.auth.getSession();
    if (!session) {
      throw redirect({ to: '/login' });
    }
    //Check if there is a user
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) {
      await supabase.auth.signOut();
      throw redirect({ to: '/login' });
    }

    // If user is not a shop
    if (user.user_metadata.shop !== true) {
      //if the route is "update-password", the user can
      //update his password and became a shop
      if (location.pathname === '/update-password') {
        return { user: user };
      } else {
        await supabase.auth.signOut();
        throw redirect({ to: '/login' });
      }
    }

    const { data } = await supabase.from('shops').select('id').eq('id', user.id);

    //check if user is onboarded
    if ((!data || data.length < 1) && location.pathname !== '/on-boarding') {
      throw redirect({ to: '/on-boarding' });
    }

    if (data?.length === 1 && location.pathname === '/on-boarding') {
      throw redirect({ to: '/analytics' });
    }

    return context.queryClient.ensureQueryData({
      queryKey: ['shopAttrs'],
      queryFn: async () => {
        const shopAttrs = await apiV1.get<ShopAttrs>('/shop/attributes');
        posthog.identify(shopAttrs.id, { email: user.email, ...shopAttrs});
        return shopAttrs;
      },
      staleTime: Infinity
    });
  },

  pendingComponent: PageLoader
});

function RouteComponent() {
  return (<AuthLayout><Outlet /></AuthLayout>);
}
