import { supabase } from './supabase';

export async function signOut() {
  try {
    await supabase.auth.signOut();
    window.location.reload();
  } catch (e) {
    console.error(e);
  }
}
