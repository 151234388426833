import { Button, Form, Modal } from 'antd';
import SubscriptionInput from '../SubscriptionInput';
import { Stripe } from 'stripe';
import { apiV1 } from '../../utils/apiClient';
import { SubscriptionInputValues } from '@next-is-free-monorepo/next-is-free-types';
import { useMutation } from '@tanstack/react-query';


const ChangePlanModal = ({ open, setChangePlanModal, subscriptionInterval }: {
  open: boolean,
  setChangePlanModal: (open: boolean) => void,
  subscriptionInterval: Stripe.Plan.Interval
}) => {


  const [form] = Form.useForm();
  const subscriptionInputValue = Form.useWatch('subscription', form);


  const intervalToLookup = {
    year: 'yearly',
    month: 'monthly',
    day: 'daily',
    week: 'weekly'
  };

  async function handleSubmit(values: {subscription: SubscriptionInputValues}) {
    await apiV1.put('/shop/subscription', { promoCode: values.subscription.promoCode, subscriptionType: values.subscription.subscriptionType });
  }

  const changeSubscriptionPlan = useMutation({mutationKey: ['subscription', 'update'], mutationFn: handleSubmit, onSuccess: () => {window.location.reload()}})

  return (
    <Modal onCancel={()=>setChangePlanModal(false)} open={open} title={'Seleziona un nuovo piano di abbonamento'} okText={'Passa al nuovo piano'}
           footer={null}>
      <Form onFinish={changeSubscriptionPlan.mutate} form={form} layout={'vertical'}
            initialValues={{
              subscription: {
                promoCode: '',
                subscriptionType: intervalToLookup[subscriptionInterval]
              }
            }}>
        <Form.Item label={'Codice promo'} name={'subscription'}>
          <SubscriptionInput />
        </Form.Item>
        <Button loading={changeSubscriptionPlan.isPending} style={{ width: '100%', marginTop: 15 }} size={'large'} type="primary"
                disabled={subscriptionInputValue?.subscriptionType === intervalToLookup[subscriptionInterval] && !subscriptionInputValue.promoCode || !subscriptionInputValue?.subscriptionType}
                htmlType={'submit'}>Cambia piano di abbonamento</Button>
      </Form>
    </Modal>
  );
};
export default ChangePlanModal;
