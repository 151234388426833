import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { COLORS } from '@next-is-free-monorepo/globals';
import { gray } from '@ant-design/colors';
import { ConfigProvider } from 'antd';
import App from './App';
import '../styles/layouts.css';
import { PostHogProvider } from 'posthog-js/react';
import { PostHogConfig } from 'posthog-js';

const postHogOptions = {
  api_host: import.meta.env.VITE_PUBLIC_POSTHOG_HOST,
  autocapture: false,
  capture_pageview: false,
};

// Render the app
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const rootElement = document.getElementById('root')!;
if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <StrictMode>
      <PostHogProvider options={postHogOptions} apiKey={import.meta.env.VITE_PUBLIC_POSTHOG_KEY}>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: COLORS.primary,
              colorBgContainer: COLORS.lightGray
            },
            components: {
              Menu: {
                /* fontWeight: 900,*/
                itemBorderRadius: 2,
                fontSize: 15,
                itemSelectedBg: COLORS.primary,
                itemSelectedColor: COLORS.black,
                itemColor: gray[6]
              },
              Input: {
                colorBgContainer: 'white',
                borderRadius: 5,
                padding: 0,
                controlHeight: 34,
                controlHeightLG: 40,
                fontSizeLG: 15,
                colorBorder: COLORS.mediumGray,
                colorBgContainerDisabled: COLORS.lightGray,
                colorTextDisabled: COLORS.gray
              },
              InputNumber: {
                colorBgContainer: 'white',
                addonBg: 'white',
                controlHeight: 34,
                colorBorder: COLORS.mediumGray,
                borderRadius: 5,
                colorBgContainerDisabled: COLORS.lightGray,
                colorTextDisabled: COLORS.gray
              },
              Select: {
                colorBgContainer: 'white',
                borderRadiusLG: 5,
                borderRadius: 5,
                controlHeight: 34,
                fontSizeLG: 15,
                colorBorder: COLORS.mediumGray,
                colorTextDisabled: COLORS.gray
              },
              DatePicker: {
                colorBgContainer: 'white',
                colorBorder: COLORS.mediumGray
              },
              Form: {
                verticalLabelMargin: 0,
                verticalLabelPadding: 2,
                itemMarginBottom: 12,
                labelColor: gray[6]
              },
              Button: {
                defaultShadow: '',
                primaryShadow: '',
                dangerShadow: '',
                borderRadius: 20,
                lineWidth: 0,
                defaultHoverColor: COLORS.gray,
                defaultActiveBg: COLORS.mediumGray,
                defaultActiveColor: COLORS.darkBg,
                defaultColor: 'black',
                defaultBg: COLORS.lightGray,
                defaultHoverBg: COLORS.lightGray,
                colorPrimaryBg: COLORS.black,
                /*primaryBg: 'red',*/
                borderRadiusLG: 5,
                fontSizeLG: 14,
                colorPrimary: 'black',
                colorPrimaryBgHover: COLORS.lightGray,
                colorPrimaryHover: COLORS.darkBg,
                colorPrimaryActive: COLORS.gray,
                borderRadiusSM: 20
              },
              Table: {
                headerBg: COLORS.lightGray,
                colorPrimaryBg: 'red',
                rowHoverBg: 'white',
                colorBgContainer: 'white',
                /*   tableBorderRadius: 1,*/
                headerBorderRadius: 0
              },
              Slider: {
                trackBg: COLORS.black,
                trackHoverBg: COLORS.gray,
                dotBorderColor: COLORS.black,
                handleColor: COLORS.black,
                handleActiveColor: COLORS.black,
                handleActiveOutlineColor: 'transparent'
              }
            }
          }}
        >
          <App />
        </ConfigProvider>
      </PostHogProvider>
    </StrictMode>
  );
}
