import {z} from "zod";

export const AddressValueSchema = z.discriminatedUnion('manual', [
    z.object({
        manual: z.literal(true),
        address: z.string().min(1), //Address line 1 (e.g., street, PO Box, or company name).
        city: z.string().min(1), //City, district, suburb, town, or village.
        postal_code: z.string().min(1), //ZIP or postal code.
        state: z.string().min(1) //State, county, province, or region.
    }),
    z.object({
        manual: z.literal(false),
        selected: z.object({label: z.string(), value: z.string()}),
        sessionToken: z.string().uuid().nullish()
    })
])

export type AddressValueType = z.infer<typeof AddressValueSchema>
