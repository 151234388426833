import * as React from 'react'
import { createFileRoute, useNavigate } from '@tanstack/react-router';
import { Button, DatePicker, Flex, Skeleton, Table } from 'antd'
import { Suspense, useState } from 'react'
import dayjs from 'dayjs'
import { blue, green, red } from '@ant-design/colors';
import { useQuery } from '@tanstack/react-query'
import PageLoader from '../../../../microcomponents/PageLoader'
import { transactionsQueryOptions } from '../../../../utils/queryOptions'
import SalesPointsSelect from '../../../../components/SalesPointsSelect'
import { IconScan } from '@tabler/icons-react';
import useShopAttrs from '../../../../utils/hooks/useShopAttrs';

export const Route = createFileRoute('/_authenticated/transactions/')({
  component: RouteComponent,
  pendingComponent: PageLoader,
})


function RouteComponent() {
  const [selectedDate, setSelectedDate] = useState<dayjs.Dayjs>(dayjs())
  const [selectedSalesPointId, setSelectedSalesPointId] = useState('')
  const navigate = useNavigate()
  const shopAttrs = useShopAttrs()
  const transactions = useQuery(
    transactionsQueryOptions(
      selectedDate,
      selectedDate.subtract(1, 'day'),
      selectedSalesPointId,
    ),
  )

  const columns = [
    {
      title: 'Ora',
      dataIndex: 'created_at',
      key: 'hour',
      render: (value: string) => (
        <p style={{ textTransform: 'capitalize' }}>
          {dayjs(value).locale('it').format('HH:mm')}
        </p>
      ),
    },
    {
      title: 'Punto Vendita',
      dataIndex: 'sales_point_name',
      key: 'sales_point_name',
    },
    {
      title: 'Tipo',
      dataIndex: 'type',
      key: 'type',
      render: (value: number) =>
        value === 0 ? (
          <p
            style={{
              backgroundColor: green[2],
              width: 'fit-content',
              padding: '1px 10px',
              borderRadius: 4,
              color: green[7],
            }}
          >
            Assegnati
          </p>
        ) : (
          <p
            style={{
              backgroundColor: blue[2],
              width: 'fit-content',
              padding: '1px 10px',
              borderRadius: 4,
              color: blue[7],
            }}
          >
            Promozione
          </p>
        ),
    },
    {
      title: 'Punti',
      dataIndex: 'points',
      key: 'points',
    },
    {
      title: 'Importo',
      dataIndex: 'euro',
      key: 'euro',
      render: (value: number) => <p>{value ? value + '€' : '-'}</p>,
    },
  ]

  const disabledDate: any = (current: any) => {
    // Can not select days before today and today
    return current && current > dayjs().endOf('day')
  }

  return (
    <div>
      <Flex
        gap={10}
        wrap={'wrap'}
        style={{ marginBottom: 30 }}
        justify={'space-between'}
      >
        <h1>Transazioni</h1>
        <Flex gap={5} wrap={'wrap'}>
          <Suspense fallback={<Skeleton.Input style={{ width: 200 }} active />}>
            <SalesPointsSelect size={'large'}
              selectedSalesPointId={selectedSalesPointId}
              setSelectedSalesPointId={setSelectedSalesPointId}
            />
          </Suspense>
          <DatePicker
            style={{ minWidth: 200, flex: 1 }}
            allowClear={false}
            format="YYYY-MM-DD"
            disabledDate={disabledDate}
            onChange={setSelectedDate}
            defaultValue={selectedDate}
          />
          <Button style={{flex: 1}} disabled={!shopAttrs.data.isVisible} onClick={()=>navigate({to: '/transactions/capture'})} icon={<IconScan />} type={'primary'} size={'large'}>
            Scansiona QR code
          </Button>
        </Flex>
      </Flex>
      <Table
        rowKey={'created_at'}
        style={{ overflow: 'hidden' }}
        pagination={{ pageSize: 100 }}
        size={'small'}
        loading={transactions.isLoading}
        dataSource={transactions.data}
        columns={columns}
      />
    </div>
  )
}
