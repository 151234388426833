import React, { useEffect } from 'react';
import { useNavigate } from '@tanstack/react-router';
import PageLoader from '../microcomponents/PageLoader';

const NotFound = ()=>{

  const navigate = useNavigate();

  useEffect(()=>{
    navigate( {to: '/login'});
  },[]);

  return(<PageLoader/>);
}
export default NotFound;
