import {
  Button,
  Form,
  Input,
  message,
  Modal,
  Skeleton,
  Space
} from 'antd';
import {
  DeleteOutlined,
  PlusOutlined,
  ShopOutlined
} from '@ant-design/icons';
import { useState } from 'react';
import { blue } from '@ant-design/colors';
import ConfirmModal from '../ConfirmModal';
import { useMutation, useQuery, useQueryClient, useSuspenseQuery } from '@tanstack/react-query';
import { authDelete, authGet, authPost, authPut } from '../../utils/authCalls';
import Wrapper from '../Wrapper';
import AddressFormItem from '../AddressFormItem';
import * as Sentry from '@sentry/react';
import useShopAttrs from '../../utils/hooks/useShopAttrs';
import { salesPointsQueryOptions } from '../../utils/queryOptions';
import { salesPointBaseSchema, SalesPointPreview } from '@next-is-free-monorepo/next-is-free-types';
import { apiV1 } from '../../utils/apiClient';

/*interface SalesPointWithDefault extends SalesPointType {
    is_default: boolean,
    id: string
}*/

const SalesPointsForm = () => {
  const [salesPointData, setSalesPointData] = useState<SalesPointPreview | undefined | 'new'>();
  /*    const [salesPointData, setSalesPointData] = useState<SalesPointWithDefault | 'new'>();*/
  const [form] = Form.useForm();
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmCreation, setConfirmCreation] = useState(false);

  const shopAttrs = useShopAttrs();
  const queryClient = useQueryClient();

  const salesPoints = useSuspenseQuery(salesPointsQueryOptions());


  const createNewSalePoint = {
    mutationFn: async () => {
      const values = form.getFieldsValue();
      await salesPointBaseSchema.parseAsync(values);
      await apiV1.post('/shop/sales-points', values);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['sales-points'] });
      message.success('Punto vendita aggiunto');
      setConfirmCreation(false);
      setSalesPointData(undefined);
    },
    onError: async (error: Error) => {
      Sentry.withScope((scope) => {
        const serializedFormData = JSON.parse(JSON.stringify(form.getFieldsValue()));
        scope.setExtra('formData', serializedFormData);
        Sentry.captureException(error);
      });
    }
  };

  const editSalePoint = useMutation({
    mutationFn: async () => {
      if (salesPointData && salesPointData !== 'new') {
        const values = form.getFieldsValue();
        await salesPointBaseSchema.parseAsync(values);
        await apiV1.put(`/shop/sales-points/${salesPointData.id}`, values);
      } else {
        throw new Error('No salesPointData');
      }
    },
    onSuccess: async () => {
      setSalesPointData(undefined);
      await queryClient.invalidateQueries({ queryKey: ['sales-points'] });
      message.success('Informazioni aggiornate');
    },
    onError: async (error: Error) => {
      message.error('Qualcosa è andato storto, riprova');
      Sentry.withScope((scope) => {
        const serializedFormData = JSON.parse(JSON.stringify(form.getFieldsValue()));
        scope.setExtra('formData', serializedFormData);
        Sentry.captureException(error);
      });
    }
  });

  const deleteSalePoint = {
    mutationFn: async () => {
      if (!salesPointData || salesPointData == 'new') {
        throw new Error('No salesPointData');
      }
      await apiV1.delete(`/shop/sales-points/${salesPointData.id}`);
    },
    onSuccess: async () => {
      setSalesPointData(undefined);
      setConfirmDelete(false);
      await queryClient.invalidateQueries({ queryKey: ['sales-points'] });
      message.success('Informazioni aggiornate');
    },
    onError: async (error: Error) => {
      Sentry.withScope((scope) => {
        scope.setExtra('salesPointData', salesPointData);
        Sentry.captureException(error);
      });
    }
  };

  async function onSubmitForm() {
    if (salesPointData === 'new') {
      setConfirmCreation(true);
      return;
    } else {
      editSalePoint.mutate();
    }
  }

  return (
    <Wrapper
      p={20}
      mt={10}
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: 30
      }}
    >
      <h3>Punti vendita</h3>

      <>
        {salesPoints.data.map((item) => (
          <div
            key={item.id}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <Space size={'middle'}>
              {!item.is_default && !shopAttrs.data.subscription_status ?
                <div><p style={{ fontSize: 16, fontWeight: 600 }}>+15€</p><p>/mese</p></div>
                :
                <ShopOutlined className={'hide-mob'}
                              style={{ fontSize: 25, color: item.is_default ? blue[4] : 'grey' }} />
              }

              <div>
                <h3>
                  {item.is_default && (
                    <p
                      style={{
                        fontSize: 12,
                        color: blue[4],
                        width: 'fit-content',
                        borderRadius: 10
                      }}
                    >
                      Principale
                    </p>
                  )}
                  {item.name}
                </h3>
                <p>{item.full_address}</p>
                {/*  <p>{!item.salesPointAddress.manual && item.salesPointAddress.selected.label}</p>*/}
              </div>
            </Space>
            <Button

              onClick={() => setSalesPointData(item)}
            >
              Modifica
            </Button>
          </div>
        ))}
        <Button onClick={() => setSalesPointData('new')}>
          <PlusOutlined />
          Aggiungi punto vendita
        </Button>
      </>
      {salesPointData && (
        <Modal
          okText={'Salva'}
          footer={
            <>
              <Button loading={editSalePoint.isPending}
                      onClick={() => {
                        setSalesPointData(undefined);
                        form.resetFields();
                      }}
              >
                Annulla
              </Button>
              {
                (salesPointData !== 'new' && !salesPointData.is_default) &&
                <Button loading={editSalePoint.isPending} onClick={() => setConfirmDelete(true)} danger>
                  <DeleteOutlined /> Elimina
                </Button>
              }
              <Button onClick={() => form.submit()} loading={editSalePoint.isPending} type={'primary'}>Salva</Button>
            </>
          }
          cancelText={salesPointData === 'new' ? 'Annulla' : 'Elimina'}
          onOk={() => form.submit()}
          onCancel={() => setSalesPointData(undefined)}
          open={!!salesPointData}
        >
          <Form
            initialValues={salesPointData !== 'new' ? salesPointData : {}}
            onFinish={onSubmitForm}
            form={form}
            layout={'vertical'}
            style={{ paddingTop: 30, paddingBottom: 10 }}
            preserve={false}
          >
            <Form.Item
              rules={[
                {
                  required: true,
                  message: 'Inserisci il nome del punto vendita'
                }
              ]}
              required={false}
              name={'name'}
              label={'Nome del punto vendita'}
              preserve={false}
            >
              <Input
                placeholder={'Nome del punto vendita'}
              />
            </Form.Item>
            <AddressFormItem name={'address'} form={form} />
          </Form>
          <ConfirmModal open={confirmDelete} setOpen={setConfirmDelete} mutationOptions={deleteSalePoint}
                        title={'Attenzione'} buttonText={'Annulla abbonamento'} danger={true}>
            <p>L'abbonamento rimarrà attivo fino al termine del periodo di fatturazione corrente e poi sarà
              eliminato automaticamente</p>
          </ConfirmModal>
          <ConfirmModal open={confirmCreation}
                        setOpen={setConfirmCreation}
                        title={'Aggiungi'} buttonText={'Aggiungi nuovo'} mutationOptions={createNewSalePoint}
                        danger={false}>
            <div><p style={{ fontSize: 25, fontWeight: 600 }}>+15€ <span
              style={{ fontSize: 16 }}>/mese + iva</span></p><p>Il tuo abbonamento verrà aggiornato</p>
            </div>
          </ConfirmModal>
        </Modal>
      )}
    </Wrapper>
  );
};
export default SalesPointsForm;
