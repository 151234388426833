import { CustomerQrCodeType, QrCodePrefixes } from '@next-is-free-monorepo/next-is-free-types';
import { z } from 'zod';

function extractMatchingSuffix(input: string): string | null {
  const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;

  // Split the string by '%' and get the last two parts
  const parts = input.split('%');

  if (parts.length < 2) {
    return null; // Not enough parts to match the pattern
  }

  const prefix = parts[parts.length - 2];
  const uuid = parts[parts.length - 1];

  // Check if the last part is a valid UUID
  if (uuidRegex.test(uuid)) {
    const qrPrefix = QrCodePrefixes.safeParse(prefix.slice(-1))
    if (prefix.slice(-3) === 'add') {
      return `${prefix.slice(-3)}%${uuid}`; // Return the matching part
    }else if(qrPrefix.success){
      return `${prefix.slice(-1)}%${uuid}`;
    }
  }

  return null; // No match found
}

export async function checkCustomerQrCode(qrCode: string): Promise<{type: CustomerQrCodeType, userId: string, clean: string}>{

  const cleanQrCode = extractMatchingSuffix(qrCode.replace(/'/g, '-'));

  if(!cleanQrCode){
    throw new Error();
  }

  const qrCodeArray = cleanQrCode.split('%');
  try{
    await z.string().uuid().parseAsync(qrCodeArray[1]);
  }catch (e){
    throw new Error();
  }


  if (qrCodeArray[0] === 'add') {
    return {type: 'addPoints', userId: qrCodeArray[1], clean: cleanQrCode};
  }

  try {
    await QrCodePrefixes.parseAsync(qrCodeArray[0]);
    return {type: 'goal', userId: qrCodeArray[1], clean: cleanQrCode};
  }catch (e){
    throw new Error();
  }

}
